import { yardValue } from "../../lib/constant"

export const Calculator = (parts, quen = 1) => {
    const sizeCheck = parts?.find(data => data.type === 'size')
    const yardSize = yardValue[sizeCheck.sizeName]
    const partCount = parts?.map(data => {
        if (data.type === 'size') {
            return data.originalPrice * quen
        } else if (['Front', 'Back'].includes(data.type)) {
            const sizeYard = yardSize.yard * quen > 1 ? yardSize.yard * quen : 1
            const fabricValue = data?.originalPrice ? sizeYard * data?.originalPrice : null
            return fabricValue
        } else if (data.type === 'border') {
            const sizeYard = yardSize[data.name] * quen > 1 ? yardSize[data.name] * quen : 1
            if (sizeYard) {
                const borderValue = sizeYard * data?.originalPrice
                const borderCost = data.laberSellPrice * quen
                return borderValue + borderCost
            }
        } else if (data.type === 'band') {
            let yardSide = data?.bandType === 'double' ? 2 : 1
            const sizeYard = yardSize[data.name] * quen * yardSide
            if (sizeYard) {
                const bandValue = sizeYard * data?.originalPrice
                const bandCost = data.laberSellPrice * quen
                return bandValue + bandCost
            }
        } else if (data.type === 'insertType') {
            return data.originalPrice * quen
        }
    })
    const sumData = partCount?.filter(Boolean).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return sumData
}