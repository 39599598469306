import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SIGN_IN, VERIFY_EMAIL } from "./apollo/gql/query/user.query";
import { useModalAction } from "./components/modal/modal.context";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import Home from "./pages/Home";
import SharePage from "./pages/Home/SharePage";
import Orders from "./pages/Home/orders";
import Pricing from "./pages/Home/orders/pricing";

import { Analytics, Auth } from "aws-amplify";
import { awsExports, awsUsExports } from "./aws_exports";
import { USER_EMAIL, USER_PASSWORD } from "./aws.config";

import dayjs from 'dayjs';

import 'dayjs/locale/zh-cn';

import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

declare global {
  interface Window {
    webpSupport?: boolean;
  }
}

const App = () => {
  const [loading, setLoading] = useState(false);
  var token = new URLSearchParams(window.location.search).get('token');

  const [verifyEmailMutation] = useMutation(VERIFY_EMAIL)
  const { openModal, closeModal } = useModalAction();
  const [preFix, setPreFix] = useState('')
  const [auth, setAuth] = useState(false)
  const accessToken = localStorage.getItem('access-token');
  const checkWebPSupport = () => {
    let elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      window.webpSupport = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    } else {
      window.webpSupport = false;
    }
  }


  useEffect(() => {
    fetch("https://apis.polyfoundry.com/prod/check")
      .then((res) => res.text())
      .then((res) => {
        setPreFix(res)
      })
      .catch((err) => setPreFix("app"));
  }, [])

  useEffect(() => {
    checkWebPSupport()
    const verifyEmailAndSignIn = async () => {
      try {
        closeModal();
        setLoading(true)
        // Verify the email
        const response = await verifyEmailMutation({ variables: { code: token } });
        await toast.success('Login successfully');
        setLoading(false)
        localStorage.setItem('access-token', response?.data?.verifyEmail?.token);
        localStorage.setItem('userData', JSON?.stringify(response?.data?.verifyEmail?.user))
        window.location.assign("/");
      } catch (error: any) {
        toast.error(error?.message);
        setLoading(false)
        openModal('LOGIN')
      }
    };

    if (accessToken) {
      // User is already logged in, close modal if it's open
      closeModal();
    } else {
      // User is not logged in, show the login modal
      openModal('LOGIN');
    }
    if (window?.location?.pathname === "/verify-email") {
      verifyEmailAndSignIn();
    }

    if (window?.location?.pathname === `/reset-password`) openModal('CREATE_PASSWORD');

    if (window?.location?.pathname?.includes('/project/')) closeModal();

  }, []);

  if (preFix && !auth) {
    if (preFix === "app") {
      Auth.configure(awsExports);
      setAuth(true)
    } else if (preFix === "us") {
      Auth.configure(awsUsExports);
      setAuth(true)
    }
    Analytics.configure({ disabled: true });
    Auth.signIn(USER_EMAIL, USER_PASSWORD).then(() => console.log("User Logged In")).catch((err: any) => console.log("=========>Error", err)) // NEED TO LOGIN FOR AWS SECURITY
  }

  dayjs.extend(updateLocale);
  dayjs.updateLocale('zh-cn', {
    weekStart: 0,
  });
  dayjs.extend(weekday)
  dayjs.extend(localeData)

  return (
    <>
      {
        loading ? <div className="flex justify-center items-center" style={{ height: '100vh' }}><Spinner /></div> :
          <>
            <BrowserRouter>
              <div className="App">
                <Routes>
                  <Route path="/" element={<Home category="pillow" />} />
                  <Route path="/pillow" element={<Home category="pillow" />} />
                  <Route path="/upholstery" element={<Home category="upholstery" />} />
                  {/* <Route path="/upholstery" element={<Home category="pillow" />} /> */}
                  <Route path="/drapery" element={<Home category="drapery" />} />
                  <Route path="/vintage" element={<Home category="vintage" />} />
                  <Route path="/project/:id" element={<SharePage />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/orders/pricing" element={<Pricing />} />
                </Routes>
              </div>
            </BrowserRouter>
          </>
      }
    </>
  );

}
export default App;