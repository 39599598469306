import ProgressBox from '../progress-box/progress-box';
import { filterOrderStatus, ORDER_STATUS, ORDER_USER_STATUS } from '../constants/order-status';
import { isUserAdmin } from '../../utils/utils';

interface Props {
  orderStatus?: any;
  paymentStatus?: any;
}

const OrderStatusProgressBox = ({ paymentStatus, orderStatus }: Props) => {
  let newOrderStatue = orderStatus
  const newData = isUserAdmin() ? ORDER_STATUS : ORDER_USER_STATUS
  let currentStatusIndex = newData.findIndex((o) => o.status === orderStatus) ?? 0;
  const filterStatus = filterOrderStatus(newData, currentStatusIndex);

  if (!isUserAdmin()) {
    const userData = ['pending', 'paidOrder', 'sentToWorkroom', 'readyForPickup']
    if (!userData?.includes(newOrderStatue)) {
      if (['vendorOrder', 'shipped', 'received']?.includes(newOrderStatue)) {
        newOrderStatue = "paidOrder"
        currentStatusIndex = 1
      } else {
        newOrderStatue = "readyForPickup"
        currentStatusIndex = 3
      }
    }
  }

  return (
    <ProgressBox
      data={filterStatus}
      status={newOrderStatue!}
      filledIndex={currentStatusIndex >= 0 ? currentStatusIndex : 0}
    />
  );
};

export default OrderStatusProgressBox;
