export const reduceBase64ImageSize = (base64Image) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64Image;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width >= 2048 ? img.width / 2 : img.width;
            canvas.height = img.height >= 2048 ? img.height / 2 : img.height;
            ctx.drawImage(img, 0, 0);

            // Resize the image to half its original size
            const resizedWidth = canvas.width;
            const resizedHeight = canvas.height;
            canvas.width = resizedWidth;
            canvas.height = resizedHeight;
            ctx.drawImage(img, 0, 0, resizedWidth, resizedHeight);

            // Compress the image
            canvas.toBlob((blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const compressedBase64 = reader.result;
                    resolve(compressedBase64);
                };
            }, 'image/jpeg', 0.7);
        };
        img.onerror = (error) => reject(error);
    });
}
