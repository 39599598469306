import { gql } from '@apollo/client';

export const UPDATE_ORDER_SUMMARY = gql`
  mutation updateOrderSummary($input:inputOfAddProductSummary ){
    updateOrderSummary(input:$input ){
      _id
      productSummary
      name
    }
  }
`