import React, { useState } from 'react'
import { useModalAction } from '../modal/modal.context';
import cn from 'classnames';
import { Form, Input, Spinner } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormValidationMessages, emailValidation } from '../../lib/form-validation-error';
import { useMutation } from '@apollo/client';
import { SIGN_IN } from '../../apollo/gql/query/user.query';
import { toast } from 'react-toastify';
import { Eye } from '../../icons/eye-icon';
import { EyeOff } from '../../icons/eye-off-icon';
import Logo from "../../assets/logo.png"

const Login = () => {
  const [loading, setLoading] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false);

  const { openModal, closeModal } = useModalAction();

  // const history = useNavigate();

  const [signInMutation] = useMutation(SIGN_IN)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().matches(emailValidation, FormValidationMessages?.email?.INVALID).required(FormValidationMessages?.email?.REQUIRED),
      password: Yup.string().required(FormValidationMessages?.password?.REQUIRED),
    }),
    onSubmit: async (values) => {

      setLoading(true)

      const input = {
        email: values?.email,
        password: values?.password,
      }

      signInMutation({ variables: input })
        .then((response) => {
          setLoading(false)

          // IF USER IS NOT VERIFIED
          if (!response?.data?.customerLogin?.user?.isEmailVerify) {
            const user = { ...response?.data?.customerLogin?.user, token: response?.data?.customerLogin?.token }
            localStorage.setItem('userData', JSON?.stringify(user))
            localStorage.setItem('access-token', response?.data?.customerLogin?.token)
            closeModal();
            openModal('EMAIL_CONFIRMATION');
            toast.success(`We sended mail on '${values?.email}' for verification`)
          } else {
            toast.success('Login successfully')
            localStorage.setItem('access-token', response?.data?.customerLogin?.token)
            localStorage.setItem('userData', JSON?.stringify(response?.data?.customerLogin?.user))
            closeModal();
            window.location.assign("/");
            validation.resetForm()
          }
        }).catch((error) => {
          setLoading(false);
          toast.error(error.message)
        }
        )
    }
  });


  return (

    <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light md:h-auto md:min-h-0 md:max-w-[530px] md:rounded-md">
      <div className="items-center p-10">
        <div className='mb-4 flex justify-center'>
          <img src={Logo} alt="logo" style={{ width: '250px' }} />
        </div>
        <div className='mb-4'>
          {/* <h3 className='text-lg mb-1 text-accent font-medium'>Login</h3> */}
          <p className='text-md text-[#6B7280] text-center'>Login to admin</p>
        </div>

        <Form className="tablelist-form" onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}>
          <div className='my-2'>
            <label className='text-sm mb-1' htmlFor="">Email</label>
            <Input
              name='email'
              type="email"
              autoComplete="off"
              className={cn('mt-1 py-2.5 border-gray-300 item-center w-full overflow-hidden truncate rounded-md text-sm text-heading placeholder-gray-500 transition duration-300 ease-in-out focus:border-accent focus:outline-0 focus:ring-0', { 'border-red-500': validation.touched.email && validation.errors.email })}
              placeholder='Enter Email'
              value={validation.values.email || ""}
              invalid={validation.touched.email && validation.errors.email ? true : false}
              onChange={validation.handleChange}
            />
            {validation.touched.email && validation.errors.email ? (
              <span className='text-[red] text-[13px] m-0'>{validation.errors.email}</span>
            ) : null}
          </div>
          <div className='my-2'>
            <label className='text-sm mb-1' htmlFor="">Password</label>
            <span className='text-muted cursor-pointer float-right text-[14px] m-0 mb-1' onClick={() => openModal('FORGOT_PASSWORD')}>Forgot password?</span>
            <div className='relative'>
              <Input
                name='password'
                type={passwordShow ? "text" : "password"}
                autoComplete="off"
                className={cn('mt-1 py-2.5 border-gray-300 item-center w-full overflow-hidden truncate rounded-md text-sm text-heading placeholder-gray-500 transition duration-300 ease-in-out focus:border-accent focus:outline-0 focus:ring-0', { 'border-red-500': validation.touched.password && validation.errors.password })}
                placeholder='Enter Password'
                value={validation.values.password || ""}
                invalid={validation.touched.password && validation.errors.password ? true : false}
                onChange={validation.handleChange}
              />
              {validation.touched.password && validation.errors.password ? (
                <span className='text-[red] text-[13px] m-0'>{validation.errors.password}</span>
              ) : null}
              <div className="absolute right-[10px] top-[30%] cursor-pointer text-muted w-[18px]" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}>{passwordShow ? <EyeOff /> : <Eye />}</div>
            </div>
          </div>
          <button disabled={loading} type='submit' className='w-full bg-accent py-2.5 mt-7 text-md font-semibold shadow-700 transition-colors hover:bg-accent-hover focus:bg-accent-hover focus:outline-0 text-light rounded-md'>{loading ? 'Loading...' : 'Sign In'}</button>
          <p className='mt-10 mb-0 text-center text-[14px]'>Don't have an account ? <span className='text-accent underline cursor-pointer' onClick={() => { openModal('REGISTER') }}>Signup</span></p>
        </Form>
      </div>
    </div>
  )
}

export default Login