export const selectStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '0.875rem',
    color: state.isSelected || state.isFocused ? '#fff' : '#212529',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    minHeight: 40,
    height: 40,
    cursor: 'pointer',
    borderBottom: '1px solid #E5E7EB',
    width: '100',
    backgroundColor: state.isSelected
      ? 'rgba(0, 159, 127)'
      : state.isFocused
        ? 'rgba(0, 159, 127)'
        : '#fff',
  }),
  control: (_: any, state: any) => ({
    width: state.selectProps.width,
    display: 'flex',
    alignItems: 'center',
    minHeight: 40,
    height: 40,
    backgroundColor: '#ffffff',
    color: '#212529',
    borderRadius: 5,
    border: '1px solid rgba(209, 213, 219)',
    borderColor: state.isFocused ? 'rgba(0, 159, 127)' : 'rgba(209, 213, 219)',
    boxShadow:
      state.menuIsOpen &&
      !state.selectProps.isMinimal &&
      '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#6B7280',
    '&:hover': {
      color: '#6B7280',
    },
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    color: state.isFocused ? '#9CA3AF' : '#cccccc',
    padding: 0,
    cursor: 'pointer',

    '&:hover': {
      color: '#9CA3AF',
    },
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    borderRadius: 5,
    border: '1px solid #E5E7EB',
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  container: (provided: any) => ({
    ...provided,
    width: '100%', // Adjust width as needed
    height: '40px',
    verticalAlign: 'center'
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: '40px',
    minHeight: 40,
    alignItems: 'center',
    paddingLeft: state.selectProps.isMinimal ? 0 : state.isRtl ? 4 : 16,
    paddingRight: state.selectProps.isMinimal ? 0 : state.isRtl ? 16 : 4,
  }),
  singleValue: (provided: any, _: any) => ({
    ...provided,
    color: '#212529',
    fontSize: '15px',
    marginTop: -10,
  }),
  multiValue: (provided: any, _: any) => ({
    ...provided,
    backgroundColor: 'rgb(var(--color-accent-400))',
    borderRadius: 9999,
    overflow: 'hidden',
    boxShadow:
      '0 0px 3px 0 rgba(0, 0, 0, 0.1), 0 0px 2px 0 rgba(0, 0, 0, 0.06)',
  }),
  multiValueLabel: (provided: any, _: any) => ({
    ...provided,
    paddingLeft: 10,
    fontSize: '0.875rem',
    color: '#ffffff',
  }),
  multiValueRemove: (provided: any, _: any) => ({
    ...provided,
    paddingLeft: 0,
    paddingRight: 8,
    color: '#ffffff',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'rgb(var(--color-accent-300))',
      color: '#F3F4F6',
    },
  }),
  placeholder: (provided: any, _: any) => ({
    ...provided,
    color: '#212529',
    fontSize: '14px'
  }),
  noOptionsMessage: (provided: any, _: any) => ({
    ...provided,
    color: '#212529',
    fontSize: '15px'
  }),
};
