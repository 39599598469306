import moment from 'moment'

export const formatDateString = (date, outputFormat = "MMM DD, YYYY") => {
  let output = '';
  if (moment(new Date(Number(date))).isValid()) {
    output = moment(new Date(Number(date))).format(outputFormat)
  }

  return output;
}


export const formatUTCDate = (date) => {
  const dateString = date;
  const formatToBeDate = new Date(dateString);

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(formatToBeDate);

  return formattedDate



  // const date = new Date(inputDate);

  // const months = [
  //   'January', 'February', 'March', 'April', 'May', 'June',
  //   'July', 'August', 'September', 'October', 'November', 'December'
  // ];

  // const day = date.getDate();
  // const month = months[date.getMonth()];
  // const year = date.getFullYear();

  // return `${day} ${month} ${year}`;
}


export const progressStatus = (orders, check) => {
  if (!orders || !orders?.length) {
    return ''
  }

  let statuses

  if (check === "orderOption") {
    statuses = orders?.filter(data => data?.name)?.filter(data => !["size", "insertType"]?.includes(data?.type))?.map(order => order?.orderStatus?.value ? order?.orderStatus?.value : "pending")?.filter(Boolean);
  } else {
    statuses = orders?.map(order => order?.orderStatus)?.filter(Boolean);
  }
  let currentStatus = 'pending';

  if (statuses.includes('pending')) {
    currentStatus = 'pending';
  } else if (statuses.includes('paidOrder')) {
    currentStatus = 'paidOrder';
  } else if (statuses.includes('vendorOrder')) {
    currentStatus = 'vendorOrder';
  } else if (statuses.includes('shipped')) {
    currentStatus = 'shipped';
  } else if (statuses.includes('received')) {
    currentStatus = 'received';
  } else if (statuses.includes('sentToWorkroom')) {
    currentStatus = 'sentToWorkroom';
  } else if (statuses.includes('readyForPickup')) {
    currentStatus = 'readyForPickup';
  } else if (statuses.includes('closed')) {
    currentStatus = 'closed';
  } else {
    currentStatus = 'unknown';
  }

  return currentStatus
}