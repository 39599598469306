import React, { useState } from 'react'
import { useModalAction } from '../modal/modal.context';
import {
  Form,
  Input,
} from "reactstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormValidationMessages, emailValidation } from '../../lib/form-validation-error';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { useMutation } from '@apollo/client';
import { SIGN_UP } from '../../apollo/gql/query/user.query'
import { EyeOff } from '../../icons/eye-off-icon';
import { Eye } from '../../icons/eye-icon';
import Select from '../select/select';
import Logo from "../../assets/logo.png"
import { fabricCompanyId } from '../../lib/constant';

const Register = () => {

  const [loading, setLoading] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false);
  const { openModal } = useModalAction();

  const typeOption = [
    { label: 'Consumer', value: 'Consumer' },
    { label: 'Trade', value: 'Trade' }
  ]

  const [signUpMutation] = useMutation(SIGN_UP)

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      password: "",
      type: { label: 'Consumer', value: 'Consumer' },
    },

    validationSchema: Yup.object({
      name: Yup.string().required(FormValidationMessages?.name?.REQUIRED),
      email: Yup.string().matches(emailValidation, FormValidationMessages?.email?.INVALID).required(FormValidationMessages?.email?.REQUIRED),
      password: Yup.string()
        .min(6, FormValidationMessages?.password?.MIN_LEN_6)
        .matches(/.*[a-z].*/, 'At least one lowercase letter')
        .matches(/.*[A-Z].*/, 'At least one uppercase letter')
        .matches(/.*[0-9].*/, 'At least one number')
        .required(FormValidationMessages?.password?.REQUIRED),
      type: Yup.object().shape({
        label: Yup.string().required(FormValidationMessages?.customerType?.REQUIRED),
        value: Yup.string().required(FormValidationMessages?.customerType?.REQUIRED)
      }),
    }),
    onSubmit: async (values: any) => {

      setLoading(true)

      const input = {
        name: values?.name,
        email: values?.email,
        password: values?.password,
        type: values?.type?.value ? values?.type?.value : '',
        domain: window.location.origin,
        companyId: fabricCompanyId
      }

      signUpMutation({ variables: { input } })
        .then((response) => {
          setLoading(false)
          toast.success('Customer created successfully')
          const userData: any = { ...response?.data?.signUp };
          localStorage.setItem('userData', JSON.stringify(userData));
          validation.resetForm()
          openModal('EMAIL_CONFIRMATION')
        }).catch((error) => {
          setLoading(false);
          toast.error(error.message)
        }
        )
    }
  });

  return (

    <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light md:h-auto md:min-h-0 md:max-w-[530px] md:rounded-md">
      <div className="items-center p-10">
        <div className='mb-4 flex justify-center'>
          <img src={Logo} alt="logo" style={{ width: '250px' }} />
        </div>
        <div className='mb-4 text-center'>
          <h3 className='text-lg mb-1 text-accent font-medium'>Register Account</h3>
          <p className='text-md text-[#6B7280]'>Fill out your information to use our custom design tool</p>
        </div>

        <Form className="tablelist-form" onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}>

          <div className='my-2'>
            <label className='text-sm mb-1' htmlFor="">Name</label>
            <Input
              name='name'
              type="text"
              autoComplete="off"
              className={cn('mt-1 py-2.5 border-gray-300 item-center w-full overflow-hidden truncate rounded-md text-sm text-heading placeholder-gray-500 transition duration-300 ease-in-out focus:border-accent focus:outline-0 focus:ring-0', { 'border-red-500': validation.touched.name && validation.errors.name })}
              placeholder='Enter Name'
              value={validation.values.name || ""}
              invalid={validation.touched.name && validation.errors.name ? true : false}
              onChange={validation.handleChange}
            />
            {validation.touched.name && validation.errors.name ? (
              <span className='text-[red] text-[13px] m-0'>{validation.errors.name}</span>
            ) : null}
          </div>

          <div className='my-2'>
            <label className='text-sm mb-1' htmlFor="">Email</label>
            <Input
              name='email'
              type="email"
              autoComplete="off"
              className={cn('mt-1 py-2.5 border-gray-300 item-center w-full overflow-hidden truncate rounded-md text-sm text-heading placeholder-gray-500 transition duration-300 ease-in-out focus:border-accent focus:outline-0 focus:ring-0', { 'border-red-500': validation.touched.email && validation.errors.email })}
              placeholder='Enter Email'
              value={validation.values.email || ""}
              invalid={validation.touched.email && validation.errors.email ? true : false}
              onChange={validation.handleChange}
            />
            {validation.touched.email && validation.errors.email ? (
              <span className='text-[red] text-[13px] m-0'>{validation.errors.email}</span>
            ) : null}
          </div>

          <div className='my-2'>
            <label className='text-sm mb-1' htmlFor="">Type</label>
            <Select
              name="type"
              options={typeOption}
              placeholder="Select Type"
              isSearchable={false}
              onChange={(selectedOption) => validation.setFieldValue("type", selectedOption)}
              className='mt-1 mb-2'
              value={validation.values.type}
            />

            {validation.touched.type && validation.errors.type ? (
              <span className='text-[red] text-[13px] m-0'>{validation.errors.type?.value}</span>
            ) : null}
          </div>
          <div className='my-2'>
            <label className='text-sm mb-1' htmlFor="">Password</label>
            <div className='relative'>
              <Input
                name='password'
                type={passwordShow ? "text" : "password"}
                autoComplete="off"
                className={cn('mt-1 py-2.5 border-gray-300 item-center w-full overflow-hidden truncate rounded-md text-sm text-heading placeholder-gray-500 transition duration-300 ease-in-out focus:border-accent focus:outline-0 focus:ring-0', { 'border-red-500': validation.touched.password && validation.errors.password })}
                placeholder='Enter Password'
                value={validation.values.password || ""}
                invalid={validation.touched.password && validation.errors.password ? true : false}
                onChange={validation.handleChange}
              />
              {validation.touched.password && validation.errors.password ? (
                <span className='text-[red] text-[13px] m-0'>{validation.errors.password}</span>
              ) : null}
              {validation.touched.password && validation.errors.password ? (
                null
              ) : <div className="absolute right-[10px] top-[30%] cursor-pointer text-muted w-[18px]" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}>
                {passwordShow ? <EyeOff /> : <Eye />}
              </div>}

            </div>
          </div>
          <p className='text-muted mb-0 mt-1 text-[14px]'>By registering you agree to <a href='https://suitepieces.com/pages/terms-conditions' target='_blank' className='text-accent underline cursor-pointer'>Terms of Use</a></p>
          <button disabled={loading} type='submit' className='w-full bg-accent py-2.5 mt-3 text-md font-semibold shadow-700 transition-colors hover:bg-accent-hover focus:bg-accent-hover focus:outline-0 text-light rounded-md'>{loading ? 'Loading...' : 'Sign Up'}</button>
          <p className='mt-10 mb-0 text-center text-[14px]'>Already have an account ? <span className='text-accent underline cursor-pointer' onClick={() => { openModal('LOGIN') }}>Signin</span></p>
        </Form>
      </div>
    </div>
  )
}

export default Register