export const FormValidationMessages = {
  name:{
    REQUIRED:'Name is required'
  },
  email: {
    REQUIRED: 'Email is required',
    INVALID: 'Must be valid email',
},
password: {
    PATTERN: /^\S+$/,
    SPACE_NOT_ALLOWED: 'Password must not include space',
    REQUIRED: 'Password is required',
    MIN_LEN_6: 'Password must be at least 6 characters',
},
customerType: {
  REQUIRED: 'Must select type',
},
}

export const emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;