import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./style.scss";
import Categories from "./Categories";
import Inspirations from "./Inspirations";
import OrderSummery from "./OrderSummery";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { loaderAtom, modelAtom, preFixAtom, uploadImageAtom } from "../../state";
import { useRecoilState, useRecoilValue } from "recoil";

const Home = (props: any) => {
    const history = useNavigate();
    const category = props.category;
    const [uploadImage, setUploadImage] = useRecoilState<any>(uploadImageAtom)
    const modelSrc = useRecoilValue<{ categoryName: string, src: string, loading: number }>(modelAtom);
    const [preFix, setPreFix] = useRecoilState<string>(preFixAtom);
    const loader = useRecoilValue(loaderAtom)
    const getCheckSupport = localStorage.getItem('checkSupport')

    const setCategory = (category: string) => {
        setUploadImage({})
        history(`/${category}`)
    }

    function support_format_webp() {
        var elem = document.createElement('canvas');

        if (!!(elem.getContext && elem.getContext('2d'))) {
            // was able or not to get WebP representation
            return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
        }
        else {
            // very old browser like IE 8, canvas not supported
            return false;
        }
    }

    useEffect(() => {
        fetch("https://apis.polyfoundry.com/prod/check")
            .then((res) => res.text())
            .then((res) => {
                setPreFix(res);
            })
            .catch((err) => console.log(err));
        if (!getCheckSupport) {
            const checkSupport = support_format_webp()
            localStorage.setItem('checkSupport', JSON.stringify(checkSupport))
        }
    }, []);

    return (
        <div className="wrapper">
            <Header />
            <div className="banner-section section pt-5 pb-5">
                <Categories setCategory={setCategory} modelSrc={modelSrc} category={category} loader={loader} />
            </div>
            <section className="product-info section">
                <Inspirations category={category} />
            </section>
            <section className="product-summary section">
                <OrderSummery />
            </section>
            <Footer />
        </div>
    );
}


export default Home;