import { Modal } from "react-bootstrap";
import Select from '../../../components/select/select';
import { Input } from 'reactstrap'

//formik
import { useFormik } from "formik";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_ORDER_SUMMARY } from "../../../apollo/gql/mutation/productSummary.mutation";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { GET_ALL_VENDOR } from "../graphQl/Query";
import { fabricCompanyId, orderOption, paymentOption } from "../../../lib/constant";
import { progressStatus } from "../../../lib/utils/utils";
import dayjs from 'dayjs';
import { Spin, DatePicker, Space } from "antd";
import moment from "moment";

function ListStatus(props: any) {

  const itemData = props?.productList?.find((d: any) => d?.name === props?.productName) || null;
  const selectedPart = props?.selectedPart

  const [vendorOrder, setVendorOrder] = useState<any>(selectedPart?.vendorOrder ? dayjs(selectedPart?.vendorOrder) : null);
  const [shipped, setShipped] = useState<any>(selectedPart?.shipped ? dayjs(selectedPart?.shipped) : null);
  const [received, setReceived] = useState<any>(selectedPart?.received ? dayjs(selectedPart?.received) : null);
  const [sentToWorkroom, setSentToWorkroom] = useState<any>(selectedPart?.sentToWorkroom ? dayjs(selectedPart?.sentToWorkroom) : null);

  const [loading, setLoading] = useState(false);
  const [mutation] = useMutation(UPDATE_ORDER_SUMMARY)

  const [partsOptions, SetPartsOptions] = useState<any>([])

  // Query 
  const [getVendors] = useLazyQuery(GET_ALL_VENDOR)

  useEffect(() => {
    const summaryFun = async () => {
      const { data } = await getVendors({
        variables: {
          "companyId": fabricCompanyId
        }
      })
      SetPartsOptions(data.getAllVendor?.map((x: any) => ({ label: x?.name, value: x?.name })))
    }
    summaryFun()
  }, [])

  const nameChange: any = {
    "Front": 'Front',
    "Back": 'Back',
    "border": "Border",
    "band": "Band"
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: selectedPart?.name || '',
      sku: selectedPart?.sku || '',
      trakingNumber: selectedPart?.trakingNumber || '',
      vendor: partsOptions?.find((x: any) => x?.value === selectedPart?.vendor) || {},
      // type: selectedPart?.type || '',
      po: selectedPart?.po || '',
      refrenceID: selectedPart?.refrenceID || '',
      shipCost: selectedPart?.shipCost || '',
      orderStatus: orderOption?.find((x: any) => x?.value === selectedPart?.orderStatus?.value) || { label: 'Pending', value: 'pending' },
      vendorOrder: vendorOrder || '',
      shipped: shipped || '',
      received: received || '',
      sentToWorkroom: sentToWorkroom || '',
    },
    onSubmit: async (values) => {

      if (values?.orderStatus) {
        setLoading(true);
        const productList = [...props?.productList];
        const singleProduct = productList.find((d: any) => d?.seriesName === props?.productSKU);

        const updatedParts = singleProduct?.parts?.map((part: any) => {
          if (selectedPart?.type === part?.type) {
            const returnData = {
              ...part,
              name: values?.name,
              sku: values?.sku,
              trakingNumber: values?.trakingNumber,
              vendor: values?.vendor?.label,
              po: values?.po,
              refrenceID: values?.refrenceID,
              shipCost: values?.shipCost,
              ...((values?.orderStatus || values?.vendorOrder || values?.shipped || values?.received || values?.sentToWorkroom) && {
                orderStatus: ["closed", "readyForPickup"].includes(values?.orderStatus?.value) ? values?.orderStatus : ["closed", "readyForPickup"].includes(part?.orderStatus?.value) ? part?.orderStatus :
                  values?.sentToWorkroom ? { label: 'Sent to Workroom', value: 'sentToWorkroom' } : values?.received ? { label: 'Received', value: 'received' } :
                    values?.shipped ? { label: 'Shipped', value: 'shipped' } : values?.vendorOrder ? { label: 'Ordered w/Vendor', value: 'vendorOrder' } : values?.orderStatus
              }),
              vendorOrder: values?.vendorOrder,
              shipped: values?.shipped,
              received: values?.received,
              sentToWorkroom: values?.sentToWorkroom,
            }
            return returnData;
          } else {
            return part;
          }
        });
        const checkOrderStatus = orderOption?.find(x => x?.value === progressStatus(updatedParts, "orderOption"))?.value || "pending"
        const products = productList.map(data => {
          if (data?.seriesName === props?.productSKU) {
            return {
              ...data,
              orderStatus: checkOrderStatus,
              parts: updatedParts
            }
          } else {
            return data
          }
        })
        let sortedProductList = [...products]
        sortedProductList.sort((a: { createdAt: Date }, b: { createdAt: Date }) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB.getTime() - dateA.getTime();
        });

        const input = {
          _id: props?.orderId,
          productSummary: sortedProductList
        }

        mutation({ variables: { input: input } })
          .then((response) => {
            toast.success('Order updated');
            props?.refetch();
            props?.toggle();
            setLoading(false);
          }).catch((error) => {
            toast.error(error.message);
            setLoading(false);
          })
      }
    },
  });

  return (
    <>
      <Modal
        show={props.visible}
        onHide={props.toggle}
        className="order-status-modal"
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <div className='items-center align-items-center flex flex-col w-100'>
            <h1 className='text-3xl font-medium mb-1'>{`${props?.productSKU} - ${props?.productName}`}</h1>
            <div>
              <p className="m-0 font-medium">Order #{props?.orderNumber} - {nameChange[selectedPart?.type]}</p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="m-0 p-0" style={{ width: '100%' }}>
          <div className="flex flex-col justify-center md:rounded-xl">
            <div className="items-center px-7 pt-0 py-8">

              <form autoComplete="off" className="tablelist-form" onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}>
                <div className='mt-4 w-full grid grid-cols-12 '>
                  <div className="lg:col-span-6 sm:col-span-12 col-span-12">

                    <div className='grid grid-cols-12 gap-4 py-3 items-center  text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-4 font-medium'>
                        <span>{`${nameChange[selectedPart?.type]} sku`}</span><br />
                      </div>
                      <div className='col-span-6 md:col-span-6 sm:col-span-12'>
                        <div className="mr-3">
                          <Input
                            name='sku'
                            type="text"
                            placeholder="Enter sku"
                            value={validation.values.sku || ""}
                            onChange={validation.handleChange}
                            className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='grid grid-cols-12 gap-4 py-3 items-center  text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-4 font-medium'>
                        <span>{`${nameChange[selectedPart?.type]} Description`}</span><br />
                      </div>
                      <div className='col-span-6 md:col-span-6 sm:col-span-12'>
                        <div className="mr-3">
                          <Input
                            name='name'
                            type="text"
                            placeholder="Enter Description"
                            value={validation.values.name || ""}
                            onChange={validation.handleChange}
                            className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='grid grid-cols-12 gap-4 py-3 items-center  text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-4 font-medium'>
                        <span>{nameChange[selectedPart?.type]}{" "}Vendor</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-6'>
                        <div className="mr-3 select-input">
                          <Select
                            className='mt-1 mb-2'
                            name={nameChange[selectedPart?.type]}
                            options={partsOptions}
                            value={validation.values.vendor || ''}
                            onChange={(e) => {
                              validation.setFieldValue("vendor", e)
                            }}
                            placeholder="Select Vendor"
                            isClearable={true}
                            isSearchable={true}
                          />
                        </div>

                      </div>
                    </div>
                    <div className='grid grid-cols-12 gap-4 py-3 items-center  text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-4 font-medium'>
                        <span>{`${nameChange[selectedPart?.type]} Vendor PO#`}</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-6'>
                        <div className="mr-3">
                          <Input
                            name='po'
                            type="text"
                            value={validation.values.po || ""}
                            placeholder={`Enter ${nameChange[selectedPart?.type]} Vendor PO#`}
                            onChange={validation.handleChange}
                            className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='grid grid-cols-12 gap-4 py-3 items-center  text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-4 font-medium'>
                        <span>Vendor Reference#</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-6'>
                        <div className="mr-3">
                          <Input
                            name='refrenceID'
                            type="text"
                            value={validation.values.refrenceID || ""}
                            placeholder="Enter Vendor Reference#"
                            onChange={validation.handleChange}
                            className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                          />
                        </div>
                      </div>
                    </div>


                    <div className='grid grid-cols-12 gap-4 py-3 items-center  text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-4 font-medium'>
                        <span>Shipping Cost</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-6'>
                        <div className="mr-3">
                          <Input
                            name='shipCost'
                            type="text"
                            value={validation.values.shipCost || ""}
                            placeholder="Enter Shipping Cost"
                            onChange={validation.handleChange}
                            className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='grid grid-cols-12 gap-4 py-3 items-center  text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-4 font-medium'>
                        <span>Tracking Number</span><br />
                      </div>
                      <div className='col-span-6 md:col-span-6 sm:col-span-12'>
                        <div className="mr-3">
                          <Input
                            name='trakingNumber'
                            type="text"
                            placeholder="Enter Tracking Number"
                            value={validation.values.trakingNumber || ""}
                            onChange={validation.handleChange}
                            className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="lg:col-span-6 sm:col-span-12 col-span-12">

                    <span className='text-[15px] font-medium'>Order Status</span>
                    <Select
                      className='mt-1'
                      name="orderStatus"
                      // menuIsOpen={true}
                      options={orderOption}
                      value={validation.values.orderStatus}
                      onChange={(selectedOption) => validation.setFieldValue("orderStatus", selectedOption)}
                      placeholder="Select Order Status"
                      isSearchable={false}
                    />

                    <div className='p-1 sm:p-5 bg-white' >

                      {/* HEADER */}
                      <div className='mt-3' style={{ borderTop: '1px solid rgba(229, 231, 235)' }}>
                        <div className='grid grid-cols-12 gap-4  py-3 ps-3 items-center order-status-row text-[15.5px] font-[400] '>
                          <div className='col-span-2 sm:col-span-2'>
                            <span className='text-md '>No.</span>
                          </div>
                          <div className='col-span-3 sm:col-span-3'>
                            <span className='text-md '>Order Status</span>
                          </div>
                          <div className='col-span-7 sm:col-span-7'>
                            <span className='text-md '></span>
                          </div>
                        </div>
                      </div>

                      {/* ALL COLUMN */}
                      <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-status-row text-[15.5px] font-[400] '>
                        <div className='col-span-2 sm:col-span-2'>
                          <span>01</span>
                        </div>
                        <div className='col-span-4 sm:col-span-4'>
                          <span>Ordered W/Vendor</span><br />
                        </div>
                        <div className='col-span-6 sm:col-span-6'>
                          <div className="mr-3">
                            <Space direction="vertical" className="w-full">
                              <DatePicker
                                name='vendorOrder'
                                id="closedInputBox"
                                value={vendorOrder}
                                format="MM-DD-YYYY"
                                onChange={(date: any, dateString: any) => {
                                  if (dateString) {
                                    const newDate = moment(dateString, "MM-DD-YYYY").format("YYYY-MM-DD")
                                    setVendorOrder(newDate ? dayjs(newDate) : null)
                                  } else {
                                    setVendorOrder(null)
                                  }
                                }}
                                className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                              />
                            </Space>
                          </div>
                        </div>
                      </div>

                      <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-status-row text-[15.5px] font-[400] '>
                        <div className='col-span-2 sm:col-span-2'>
                          <span>02</span>
                        </div>
                        <div className='col-span-4 sm:col-span-4'>
                          <span>Shipped</span><br />
                        </div>
                        <div className='col-span-6 sm:col-span-6'>
                          <div className="mr-3">
                            <Space direction="vertical" className="w-full">
                              <DatePicker
                                name='shipped'
                                id="closedInputBox"
                                value={shipped}
                                format="MM-DD-YYYY"
                                onChange={(date: any, dateString: any) => {
                                  if (dateString) {
                                    const newDate = moment(dateString, "MM-DD-YYYY").format("YYYY-MM-DD")
                                    setShipped(newDate ? dayjs(newDate) : null)
                                  } else {
                                    setShipped(null)
                                  }
                                }}
                                className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                              />
                            </Space>
                          </div>
                        </div>
                      </div>

                      <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-status-row text-[15.5px] font-[400] '>
                        <div className='col-span-2 sm:col-span-2'>
                          <span>03</span>
                        </div>
                        <div className='col-span-4 sm:col-span-4'>
                          <span>Received</span><br />
                        </div>
                        <div className='col-span-6 sm:col-span-6'>
                          <div className="mr-3">
                            <Space direction="vertical" className="w-full">
                              <DatePicker
                                name='received'
                                id="closedInputBox"
                                value={received}
                                format="MM-DD-YYYY"
                                onChange={(date: any, dateString: any) => {
                                  if (dateString) {
                                    const newDate = moment(dateString, "MM-DD-YYYY").format("YYYY-MM-DD")
                                    setReceived(newDate ? dayjs(newDate) : null)
                                  } else {
                                    setReceived(null)
                                  }
                                }}
                                className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                              />
                            </Space>
                          </div>
                        </div>
                      </div>

                      <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-status-row text-[15.5px] font-[400] '>
                        <div className='col-span-2 sm:col-span-2'>
                          <span>04</span>
                        </div>
                        <div className='col-span-4 sm:col-span-4'>
                          <span>Sent to Workroom</span><br />
                        </div>
                        <div className='col-span-6 sm:col-span-6'>
                          <div className="mr-3">
                            <Space direction="vertical" className="w-full">
                              <DatePicker
                                name='sentToWorkroom'
                                id="closedInputBox"
                                value={sentToWorkroom}
                                format="MM-DD-YYYY"
                                onChange={(date: any, dateString: any) => {
                                  if (dateString) {
                                    const newDate = moment(dateString, "MM-DD-YYYY").format("YYYY-MM-DD")
                                    setSentToWorkroom(newDate ? dayjs(newDate) : null)
                                  } else {
                                    setSentToWorkroom(null)
                                  }
                                }}
                                className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                              />
                            </Space>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className='w-full flex justify-center mt-10'>
                  <button disabled={loading} type='submit' className='flex items-center justify-center rounded border-black border-[1.5px] py-2.5 px-10 text-[12px] font-medium bg-black text-white lg:text-base me-4'>Save {loading && <Spin className="submit-loader" />}</button>
                  <button disabled={loading} type='reset' onClick={props?.toggle} className='flex items-center justify-center rounded border-black border-[2px] py-2.5 px-8 text-[12px] font-medium  text-black lg:text-base'>Cancel</button>
                </div>
              </form>


            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
}
export default ListStatus;