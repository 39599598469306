import { gql } from '@apollo/client';

export const GET_FABRICS = gql`
query getFabrics($input: fabricInput) {
  getFabrics(input: $input) {
    totalFinishes
    finishes {
      _id
      name
      code
      finish
      finishThumb
      finishRenderedThumb
      roughness
      roughnessOriginal
      roughnessThumb
      metalness
      metalnessThumb
      uploadImageUrl
      ao
      aoThumb
      aoValue
      diffuse
      diffuseOriginal
      isNormalFinish
      normalImageUrl
      matelnessFactor
      normalFactor
      roughnessFactor
      diffuseThumb
      normal
      normalThumb
      transparencyValue
      transparency
      transparencyOriginal
      transparencyThumb
      metallicRoughness
      metallicRoughnessThumb
      status
      materialCategory
      parentMaterialCategory
      uniqueCode
      companyId
      description
      createdAt
      createdByUserName
      modifiedAt
      modifiedByUserName
      tags
      isPaid
      isDemo
      price
      size
      deletedAt
      deletedBy
      deletedByName
      isPublic
      isCustomize
      isAlphaPattern
      alphaTest
      alphaDiffuse
      isLibraryFinish
      shineValue
      colorValue
      glassValue
      glossiness
      displacement
      displacementThumb
      displacementScale
      displacementBias
      repeatX
      repeatY
      offsetX
      offsetY
      rotate
      vendor
      costPrice
      sellPrice
      materialInsertType
    }
  }
}

`

export const GET_PRODUCT_SUMMARY = gql`
query getProductSummaryByUniCode ($uniqueCode:String){
  getProductSummaryByUniCode(uniqueCode:$uniqueCode){
    name
    email
    type
    productSummary
    uniqueCode
  }
}
`

export const GET_ALL_VENDOR = gql`
query getAllVendor($companyId: String) {
  getAllVendor(companyId: $companyId){
    _id
    name
    createdAt
  }
}
`