import { Link } from 'react-router-dom';
import MyOrderCard from './my-order-card';
import { isUserAdmin } from '../../utils/utils';

export default function MyOrders({ productSummary, setOrder }: any) {

  return (
    <>
      <div className="bg-white p-4">

        <div className='flex justify-between mb-4 items-center whitespace-nowrap'>
          <h2 className='text-lg sm:text-xl font-semibold'>My Orders</h2>
          {isUserAdmin() && <Link to="/orders/pricing"><button className='xl:px-5 lg:px-3 px-7 py-2 rounded-[4px] text-sm bg-accent bg-opacity-[.15] !text-accent items-center justify-center text-[9px] xs:text-sm'>Pricing</button></Link>}
        </div>

        <MyOrderCard productSummary={productSummary} setOrder={setOrder} />
      </div>
    </>
  );
}
