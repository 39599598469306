import React, { useState } from 'react'
import { useModalAction } from '../modal/modal.context';
import { Form, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { FormValidationMessages, emailValidation } from '../../lib/form-validation-error';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import { RESEND_EMAIL } from '../../apollo/gql/query/user.query';
import { useMutation } from '@apollo/client';

const ResendEmailConfirmation = () => {
  const { openModal, closeModal } = useModalAction();
  const [loading, setLoading] = useState(false)

  const [resendEmailMutation] = useMutation(RESEND_EMAIL)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().matches(emailValidation, FormValidationMessages?.email?.INVALID).required(FormValidationMessages?.email?.REQUIRED),
    }),
    onSubmit: async (values) => {

      setLoading(true)

      const input = {
        email: values?.email,
        domain: window.location.origin
      }

      resendEmailMutation({ variables: { input } })
        .then((response) => {
          setLoading(false)
          toast.success('Verification link sent successfully')
          localStorage.setItem('tempToken', response?.data?.resendStoreFrontEmailVerificationLink)
          validation.resetForm()
        }).catch((error) => {
          setLoading(false);
          toast.error(error.message)
        }
        )
    }
  });

  return (

    <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light md:h-auto md:min-h-0 md:max-w-[530px] md:rounded-md">
      <div className="items-center p-10">
        <div className='mb-5 flex justify-center'>
          <img src="https://cdn.shopify.com/s/files/1/0094/2584/6331/files/logo_2_fab14dcd-68e2-47e1-806b-64a5b79c740d_400x.png?v=1671636282" alt="logo" style={{ width: '120px' }} />
        </div>
        <div>
          <h3 className='text-lg mb-1 text-accent font-medium'>Resend confirmation mail</h3>
          <p className='text-md text-[#6B7280]'>Resend confirmation mail with Procat360</p>
        </div>

        <div className="mt-0 text-center">
          {/* <lord-icon
            src="https://cdn.lordicon.com/aycieyht.json"
            trigger="loop"
            colors="primary:#09b39c"
            className="avatar-xl"
            style={{ width: "120px", height: "120px" }}>
          </lord-icon> */}
        </div>

        <Form className="tablelist-form" onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}>
          <div className='my-2'>
            <label className='text-sm' htmlFor="">Email</label>
            <Input
              name='email'
              type="email"
              autoComplete="off"
              className={cn('mt-1 py-2.5 border-gray-300 item-center w-full overflow-hidden truncate rounded-md text-sm text-heading placeholder-gray-500 transition duration-300 ease-in-out focus:border-accent focus:outline-0 focus:ring-0', { 'border-red-500': validation.touched.email && validation.errors.email })}
              placeholder='Enter Email'
              value={validation.values.email || ""}
              invalid={validation.touched.email && validation.errors.email ? true : false}
              onChange={validation.handleChange}
            />
            {validation.touched.email && validation.errors.email ? (
              <span className='text-[red] text-[13px] m-0'>{validation.errors.email}</span>
            ) : null}
          </div>
          <button disabled={loading} type='submit' className='w-full bg-accent py-2.5 mt-7 text-md font-semibold shadow-700 transition-colors hover:bg-accent-hover focus:bg-accent-hover focus:outline-0 text-light rounded-md'>{loading ? 'Loading...' : 'Send Verification Link'}</button>
        </Form>
      </div>
    </div>
  )
}

export default ResendEmailConfirmation