import { Storage } from "aws-amplify";
import { APP_BUCKET, APP_BUCKET_REGION, COGNITO_REGION, SUPPLIER_BUCKET, identityPoolId, userPoolId, userPoolWebClientId, US_BUCKET_REGION, US_BUCKET } from "./aws.config";

export const awsExports = {

  Auth: {
    identityPoolId: identityPoolId,
    region: APP_BUCKET_REGION,
    userPoolId: userPoolId,
    userPoolWebClientId: userPoolWebClientId,
  },

  Storage: {
    AWSS3: {
      bucket: APP_BUCKET,
      region: APP_BUCKET_REGION,
      level: 'public'
    },
  },
  SupplierStorage: {
    AWSS3: {
      bucket: SUPPLIER_BUCKET,
      region: COGNITO_REGION,
    },
    level: 'public'
  }
};

export const SetS3Config = () => {
  Storage.configure({
    bucket: APP_BUCKET,
    level: "public",
    region: APP_BUCKET_REGION,
    identityPoolId: identityPoolId
  });
}

export const awsUsExports = {

  Auth: {
    identityPoolId: identityPoolId,
    region: APP_BUCKET_REGION,
    userPoolId: userPoolId,
    userPoolWebClientId: userPoolWebClientId,
  },

  Storage: {
    AWSS3: {
      bucket: US_BUCKET,
      region: US_BUCKET_REGION,
      level: 'public'
    },
  },
  SupplierStorage: {
    AWSS3: {
      bucket: SUPPLIER_BUCKET,
      region: COGNITO_REGION,
    },
    level: 'public'
  }
}
  ;


export const SetUsS3Config = () => {
  Storage.configure({
    bucket: US_BUCKET,
    level: "public",
    region: US_BUCKET_REGION,
    identityPoolId: identityPoolId
  });
}