import { CheckMark } from '../../icons/checkmark';
import cn from 'classnames';
import Scrollbar from '../scrollbar';
import styles from './progress-box.module.css';

type ProgressProps = {
  data: any[] | undefined;
  status: string;
  filledIndex: number;
};

const ProgressBox: React.FC<ProgressProps> = ({
  status,
  data,
  filledIndex,
}) => {
  return (
    <Scrollbar
      className="h-full w-full"
      options={{
        scrollbars: {
          autoHide: 'never',
        },
      }}
    >
      <div
        className={`${
          // @ts-ignore
          data?.length <= 3 ? 'lg:justify-center' : ''
          } flex w-full flex-col py-7 md:flex-row md:items-start justify-center`}
      >
        {data?.map((item: any, index) => (
          <div
            className={styles.progress_container}
            key={`order-status-${index}`}
          >
            <div
              className={cn(
                styles.progress_wrapper,
                index <= filledIndex ? styles.checked : ''
              )}
            >
              {index <= filledIndex ? (<div className={`${styles.status_wrapper} progress-check-back`}>

                <div className="h-4 w-3 text-col-progress-check" >
                  <CheckMark className="w-full" />
                </div>
              </div>
              ) : (
                <div className={styles.status_wrapper} >
                  <div className={styles.status_wrapper} >
                    <div className='text-col-progress'>{item.serial}</div>
                  </div>
                </div>
              )

              }
              <div className={`${index <= filledIndex ? 'progress-check-back' : ''} ${styles.bar}`} />
            </div>

            <div className="flex flex-col items-start ml-5 rtl:mr-5 md:items-center md:ml-0 rtl:md:mr-0">
              {item && (
                <span className="text-base font-bold capitalize text-body-dark text-left rtl:text-right md:px-2 md:!text-center">
                  {item?.name}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </Scrollbar >
  );
};

export default ProgressBox;
