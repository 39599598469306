import { useState, useEffect } from 'react';
import OrderStatusProgressBox from './order-status-progress-box';
import { OrderItems } from './order-items';
import { formatUTCDate, progressStatus } from '../../lib/utils/utils';
import { isUserAdmin } from '../../utils/utils';
import { UPDATE_ORDER_SUMMARY } from '../../apollo/gql/mutation/productSummary.mutation';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { orderDetailsActionAtom, orderDetailsDataAtom } from '../../state';
import { useRecoilState } from 'recoil';
import OrderDetailsEdit from './order-details-edit';
import { paymentOption } from '../../lib/constant';
import { Link } from 'react-router-dom';

function OrderDetails({ order, refetch }: any) {
  const [paymentStatus, setPaymentStatus] = useState('pending')
  const [orderStatus, setOrderStatus] = useState('pending')
  const [orderDetailsAction, setOrderDetailsAction] = useRecoilState(orderDetailsActionAtom);
  const [orderData, setOrderData] = useRecoilState<any>(orderDetailsDataAtom);
  const [isOrderEditOpen, setIsOrderEditOpen] = useState(false);

  useEffect(() => {
    setOrderData({ project: order?.project, job: order?.job, paymentRef: order?.paymentRef, designer: order?.designer })
    setPaymentStatus(order?.paymentStatus);
    setOrderStatus(order?.orderStatus);
  }, [order])

  const [mutation] = useMutation(UPDATE_ORDER_SUMMARY)

  const updateOrderMutation = (input: any) => {
    mutation({ variables: { input: input } })
      .then((response) => {
        toast.success('Order updated');
        refetch();
      }).catch((error) => {
        toast.error(error.message)
      }
      )
  }

  const handleSingleSave = (inputName: string): void => {
    const input = {
      _id: order?._id,
      [inputName]: orderData[inputName]
    }
    updateOrderMutation(input)
    setOrderDetailsAction({ ...orderDetailsAction, [inputName]: false })
  };

  const toggleOrderEdit = (order: any) => {
    setIsOrderEditOpen(!isOrderEditOpen);
  }

  return (
    <div>
      <div className="relative overflow-hidden rounded border shadow-sm">
        <div className="bg-white p-6">
          <div className='d-flex justify-content-between align-items-baseline'>

            <h4 className="mb-4 text-[16px] sm:text-lg font-semibold">
              Order Details - {order?.orderNumber || ''}
            </h4>

            {isUserAdmin() ? <button onClick={() => { toggleOrderEdit(order) }} className='text-black cursor-pointer'>Edit</button> : <></>}
          </div>

          <div className='grid grid-cols-12 py-3 pb-0 rounded-[7px] items-center md:ps-0 ps-3'>
            <div className='col-span-12 md:col-span-4 order-customer-detail-div md:ps-5'>
              <div className={`sm:mb-5 mb-3 sm:block xl:flex block items-center h-[45px]`}>
                <p className='m-0 font-medium min-w-[150px]'>Payment Status</p>
                <span className='sm:ms-0 sm:mt-3 text-muted text-[15px] font-medium m-0 text-custom-ellipse'>{paymentOption?.find(x => x?.value === paymentStatus)?.label || "Pending"}</span>
              </div>
            </div>
            <div className='col-span-12 md:col-span-4 order-customer-detail-div md:ps-5'>
              <div className={`sm:mb-5 mb-3 sm:block xl:flex block items-center h-[45px]`}>
                <p className='m-0 font-medium ' style={{ minWidth: 160 }}>Payment Reference</p>
                <span className='sm:ms-0 sm:mt-3 text-muted text-[15px] font-medium m-0 text-custom-ellipse'>{orderData?.paymentRef}</span>
              </div>
            </div>
            <div className='col-span-12 md:col-span-4 md:ps-5'>
              <div className={`sm:mb-5 mb-3 sm:block xl:flex block items-center h-[45px]`}>
                <Link target="_blank" to={`${window.location.origin}/project/${order?.uniqueCode}`}><button className='xl:px-5 lg:px-3 px-7 py-2 rounded-[4px] text-sm bg-accent bg-opacity-[.15] !text-accent items-center justify-center text-[9px] xs:text-sm'>Project Link</button></Link>
              </div>
            </div>
          </div>
          <div className='border-gray'></div>
          <div className='grid grid-cols-12 mb-10 md:ps-0 ps-3'>

            <div className='col-span-12 md:col-span-4 order-customer-detail-div md:ps-5'>
              <div className={`sm:mb-5 mb-3 sm:block xl:flex block items-center h-[45px]`}>
                <p className='m-0 font-medium min-w-[150px]'>Customer Name</p>
                <span className='sm:ms-0 sm:mt-3 text-muted text-[15px] font-medium m-0 text-custom-ellipse'>{order?.name}</span>
              </div>
              <div className={`sm:mb-5 mb-3 sm:block xl:flex block items-center h-[45px]`}>
                <span className='m-0 font-medium 2xl:min-w-[150px] min-w-auto 2xl:mr-0 mr-2'>Customer Email</span>
                <p className='sm:ms-0 sm:mt-3 text-muted text-[15px] font-medium m-0 text-custom-ellipse'>{order?.email}</p>
              </div>
            </div>

            <div className='col-span-12 md:col-span-4 order-customer-detail-div md:ps-5 lg:px-5'>


              <div className='sm:mb-5 mb-3 sm:block xl:flex block items-center h-[45px]'>
                <span className='font-medium' style={{ whiteSpace: "nowrap", minWidth: 160 }}>Project</span>
                <div className='sm:ms-0 sm:mt-1 flex'>
                  <span className='sm:ms-0 text-muted text-[15px] font-medium'>{orderData?.project}</span>
                </div>
              </div>

              <div className='sm:mb-5 mb-3 sm:block xl:flex block items-center h-[45px]'>
                <span className='font-medium' style={{ whiteSpace: "nowrap", minWidth: 160 }}>Job</span>
                <div className='sm:ms-0 sm:mt-1 d-flex'>
                  <span className='sm:ms-0 text-muted text-[15px] font-medium'>{orderData?.job}</span>
                </div>
              </div>




            </div>

            <div className='col-span-12 md:col-span-4 md:ps-5'>
              <div className={`sm:mb-5 mb-3 sm:block xl:flex block items-center h-[45px] h-[45px]`}>
                <span className='font-medium' style={{ whiteSpace: "nowrap", minWidth: "100px" }}>Date</span>
                <p className='sm:ms-0 sm:mt-3 text-muted text-[15px] font-medium m-0'>{formatUTCDate(order?.createdAt)}</p>
              </div>
              <div className='sm:mb-5 mb-3 sm:block xl:flex block items-center h-[45px]'>
                <span className='font-medium' style={{ whiteSpace: "nowrap", minWidth: "100px" }}>Designer</span>
                <div className='sm:ms-0 sm:mt-1 d-flex'>
                  <span className='sm:ms-0 text-muted text-[15px] font-medium'>{orderData?.designer}</span>
                </div>
              </div>
            </div>
          </div>

          {/* start of order Status */}
          <div className="mb-8 flex w-full items-center justify-center md:mb-12">
            <OrderStatusProgressBox
              orderStatus={progressStatus(order?.productSummary, "")}
              paymentStatus={order?.payment_status}
            />
          </div>
          {/* end of order Status */}

        </div>
      </div>

      {order?.productSummary?.length > 0 && <div className="relative overflow-hidden rounded border shadow-sm mt-10">
        <div className="bg-light" style={{ overflow: "auto" }}>
          <OrderItems refetch={refetch} products={order?.productSummary} order={order} />
        </div>
      </div>
      }
      <OrderDetailsEdit visible={isOrderEditOpen} toggle={() => toggleOrderEdit(null)} refetch={refetch} order={order} />

    </div>
  );
}
export default OrderDetails;
