import { gql } from '@apollo/client';

export const GET_COMPANY_META = gql`
query getCompanyMeta($companyId:String){
  getCompanyMeta(companyId:$companyId){
    _id
    pricing{
      widePillowBase
      squarePillowBase
      verticalBands
      insetBands
      centerBands
      oneFlange
      twoFlange
      trimFourSides
      trimTwoSides
      welt
      wideDownFillInsert
      wideHypoFillInsert
      squareDownFillInsert
      squareHypoFillInsert
      
    }
  }
}

`
