import React, { useState } from 'react'
import { useModalAction } from '../modal/modal.context';
import { Form, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { FormValidationMessages, emailValidation } from '../../lib/form-validation-error';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD } from '../../apollo/gql/query/user.query';
import Logo from "../../assets/logo.png"

const ForgotPassword = () => {
  const { openModal, closeModal } = useModalAction();
  const [loading, setLoading] = useState(false)

  const [forgotPasswordMutation] = useMutation(FORGOT_PASSWORD)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: { email: "" },

    validationSchema: Yup.object({
      email: Yup.string().matches(emailValidation, FormValidationMessages?.email?.INVALID).required(FormValidationMessages?.email?.REQUIRED),
    }),
    onSubmit: async (values) => {
      setLoading(true)

      forgotPasswordMutation({ variables: { email: values?.email, domain: window.location.origin } })
        .then((response) => {
          setLoading(false)
          toast.success("We have sent instructions to reset your password.")
          validation.resetForm()
        }).catch((error) => {
          setLoading(false);
          toast.error(error.message)
        }
        )
    }
  });

  return (

    <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light md:h-auto md:min-h-0 md:max-w-[530px] md:rounded-md">
      <div className="items-center p-10">
        <div className='mb-4 flex justify-center'>
          <img src={Logo} alt="logo" style={{ width: '250px' }} />
        </div>
        <div className='text-center'>
          <h3 className='text-lg mb-1 text-accent font-medium'>Forgot Password?</h3>
          <p className='text-md text-[#6B7280]'>Reset password with Procat360</p>
        </div>

        <div className="mt-0 text-center">
          {/* <lord-icon
            src="https://cdn.lordicon.com/aycieyht.json"
            trigger="loop"
            colors="primary:#09b39c"
            className="avatar-xl"
            style={{ width: "120px", height: "120px" }}>
          </lord-icon> */}
        </div>
        <div className="text-center mb-2 mx-2 py-4 text-[14px]" style={{ background: '#fdf3e4', color: '#cb9442' }} role="alert">
          Enter your email and instructions will be sent to you!
        </div>

        <Form className="tablelist-form" onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}>
          <div className='my-2'>
            <label className='text-sm' htmlFor="">Email</label>
            <Input
              name='email'
              type="email"
              autoComplete="off"
              className={cn('mt-1 py-2.5 border-gray-300 item-center w-full overflow-hidden truncate rounded-md text-sm text-heading placeholder-gray-500 transition duration-300 ease-in-out focus:border-accent focus:outline-0 focus:ring-0', { 'border-red-500': validation.touched.email && validation.errors.email })}
              placeholder='Enter Email'
              value={validation.values.email || ""}
              invalid={validation.touched.email && validation.errors.email ? true : false}
              onChange={validation.handleChange}
            />
            {validation.touched.email && validation.errors.email ? (
              <span className='text-[red] text-[13px] m-0'>{validation.errors.email}</span>
            ) : null}
          </div>
          <button disabled={loading} type='submit' className='w-full bg-accent py-2.5 mt-7 text-md font-semibold shadow-700 transition-colors hover:bg-accent-hover focus:bg-accent-hover focus:outline-0 text-light rounded-md'>{loading ? 'Loading...' : 'Send Reset Link'}</button>
          <p className='mt-5 mb-0 text-center text-[14px]'>Wait, I remember my password...<span className='text-accent underline cursor-pointer' onClick={() => { openModal('LOGIN') }}>Click here</span></p>
        </Form>
      </div>
    </div>
  )
}

export default ForgotPassword