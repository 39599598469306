import { Modal } from "react-bootstrap";
import Select from '../../../components/select/select';
import { Input } from 'reactstrap'
import dayjs from 'dayjs';
import { Spin, DatePicker, Space } from "antd";

//formik
import { useFormik } from "formik";
import { useState } from "react";
import { orderOption, paymentOption } from "../../../lib/constant";
import { useMutation } from "@apollo/client";
import { UPDATE_ORDER_SUMMARY } from "../../../apollo/gql/mutation/productSummary.mutation";
import { toast } from "react-toastify";
import moment from "moment";

function OrderStatus(props: any) {

  const itemData = props?.productList?.find((d: any) => d?.name === props?.productName) || null;
  const [loading, setLoading] = useState(false);
  const [readyForPickup, setReadyForPickup] = useState<any>(itemData?.readyForPickup ? dayjs(itemData?.readyForPickup) : null);
  const [mutation] = useMutation(UPDATE_ORDER_SUMMARY)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: itemData?.name || '',
      type: itemData?.type || '',
      readyForPickup: readyForPickup || '',
    },
    onSubmit: async (values) => {

      setLoading(true);
      const productList = [...props?.productList];

      const products = productList.map(data => {
        if (data?.seriesName === props?.productSKU) {
          return {
            ...data,
            name: values?.name,
            readyForPickup: (values?.readyForPickup as any),
          }
        } else {
          return data
        }
      })
      let sortedProductList = [...products]
      sortedProductList.sort((a: { createdAt: Date }, b: { createdAt: Date }) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB.getTime() - dateA.getTime();
      });

      const input = {
        _id: props?.orderId,
        productSummary: sortedProductList
      }

      mutation({ variables: { input: input } })
        .then((response) => {
          toast.success('Order updated');
          props?.refetch();
          props?.toggle();
          setLoading(false);
        }).catch((error) => {
          toast.error(error.message);
          setLoading(false);
        })
    },
  });

  return (
    <>
      <Modal
        show={props.visible}
        onHide={props.toggle}
        className="order-status-modal"
      >
        <Modal.Header closeButton>
          <div className='items-center align-items-center flex flex-col w-100'>
            <h1 className='text-3xl font-medium mb-1'>{`${props?.productSKU} - ${props?.productName}`}</h1>
            <div>
              <p className="m-0 font-medium">Order #{props?.orderNumber}</p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="m-0 p-0" style={{ width: '100%' }}>
          <div className="flex flex-col justify-center md:rounded-xl">
            <div className="items-center px-7 pt-0 py-8">

              <form autoComplete="off" className="tablelist-form" onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}>
                <div className='w-full '>
                  <div className='p-1 sm:p-5 bg-white' >
                    <div className='grid grid-cols-12 gap-4 py-2 ps-3 items-center text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-4'>
                        <span>Type</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-8'>
                        <div className="mr-3">
                          <p>{validation?.values?.type || ""}</p>
                        </div>
                      </div>
                    </div>
                    <div className='grid grid-cols-12 gap-4 py-2 ps-3 items-center text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-4'>
                        <span>Description</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-8'>
                        <div className="mr-3">
                          <Input
                            name='name'
                            type="text"
                            placeholder="Enter Description"
                            value={validation.values.name || ""}
                            onChange={validation.handleChange}
                            className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='grid grid-cols-12 gap-4 py-2 ps-3 items-center text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-4'>
                        <span>Ready for Pickup</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-8'>
                        <div className="mr-3">
                          <Space direction="vertical" className="w-full">
                            <DatePicker
                              name='readyForPickup'
                              id="closedInputBox"
                              value={readyForPickup}
                              format="MM-DD-YYYY"
                              onChange={(date: any, dateString: any) => {
                                if (dateString) {
                                  const newDate = moment(dateString, "MM-DD-YYYY").format("YYYY-MM-DD")
                                  setReadyForPickup(newDate ? dayjs(newDate) : null)
                                } else {
                                  setReadyForPickup(null)
                                }
                              }}
                              className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                            />
                          </Space>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className='w-full flex justify-center mt-10'>
                  <button disabled={loading} type='submit' className='flex items-center justify-center rounded border-black border-[1.5px] py-2.5 px-10 text-[12px] font-medium bg-black text-white lg:text-base me-4'>Save {loading && <Spin className="submit-loader" />}</button>
                  <button disabled={loading} type='reset' onClick={props?.toggle} className='flex items-center justify-center rounded border-black border-[2px] py-2.5 px-8 text-[12px] font-medium  text-black lg:text-base'>Cancel</button>
                </div>
              </form>

            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
}
export default OrderStatus;