import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import { RecoilRoot } from 'recoil';
import { ApolloProvider, InMemoryCache } from "@apollo/client";
import { client } from './apolloClient'
import { persistCache } from "apollo-cache-persist";
// import { InMemoryCache } from "apollo-cache-inmemory";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalProvider } from './components/modal/modal.context';
import ManagedModal from './components/modal/managed-modal';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

const root = ReactDOM.createRoot(
  document.getElementById('root')
  //  as HTMLElement
);
const cache = new InMemoryCache();
const storage = typeof window !== 'undefined' && window.localStorage
const waitOnCache = persistCache({ cache, storage })

waitOnCache.then(() => {
  root.render(
    <RecoilRoot>
      <ApolloProvider client={client}>
        <ModalProvider>
          <App />
          <ManagedModal />
          <ToastContainer />
        </ModalProvider>
      </ApolloProvider>
    </RecoilRoot>
  );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
