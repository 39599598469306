// ============= TESTING OR LOCAL ============= //
// export const CLOUD_FRONT_URL = "https://in.pothigara.com/public/";
// export const identityPoolId = "ap-south-1:b80f6796-d478-4d22-a667-8ad2ee1df343"
// export const userPoolId = "ap-south-1_lziB8ocwA"
// export const userPoolWebClientId = "1stdi9lmp53f3vikqv9pakebov"
// export const region = "ap-south-1";
// export const APP_BUCKET = "inpothigara"
// export const APP_BUCKET_REGION = "ap-south-1";
// export const SUPPLIER_BUCKET = "suppliers.pothigara.com"
// export const USER_EMAIL = "suitepieces-uploadfile-dont-delete@suitepieces.com";
// export const USER_PASSWORD = "suitepieces@2024";


// ============= PRODUCTION ============= //
export const CLOUD_FRONT_URL = "https://app.poly9.ai/public/";
export const identityPoolId = "ap-south-1:bf37c625-d53b-44f5-ae74-195ed4402096"
export const userPoolId = "ap-south-1_w9cF2EkpO"
export const userPoolWebClientId = "33gem5k6ih860gmrc8a6deveq2"
export const APP_BUCKET = "app.poly9.ai"
export const APP_BUCKET_REGION = "ap-south-1"
export const SUPPLIER_BUCKET = "suppliers.polynine.com"

export const US_CLOUD_FRONT_URL = "https://us.poly9.ai/public/";
export const US_BUCKET = "us.poly9.ai"
export const US_BUCKET_REGION = "us-west-1"

// // This email and PWD are user of poly9 and we are add auth verification for s3 bucket image upload
// // export const USER_EMAIL = "suitepieces-uploadfile-dont-delete@suitepieces.com";
// // export const USER_PASSWORD = "suitepieces@2024"
export const USER_EMAIL = "dron@polynine.com";
export const USER_PASSWORD = "Dron@2026";


// ============= TEST && PRODUCTION ============= //
export const COGNITO_REGION = 'ap-south-1'