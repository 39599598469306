import React, { useState } from 'react'
import { useModalAction } from '../modal/modal.context';
import { Form, Input } from 'reactstrap';
import { useFormik } from 'formik';
import { RESET_PASSWORD } from '../../apollo/gql/query/user.query';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Logo from "../../assets/logo.png"
import { EyeOff } from '../../icons/eye-off-icon';
import { Eye } from '../../icons/eye-icon';

const CreateNewPassword = () => {

  const [loading, setLoading] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false);
  const [CpasswordShow, setCPasswordShow] = useState(false);

  const { openModal } = useModalAction();
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      cPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
        .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
        .matches(RegExp('(.*[0-9].*)'), 'At least one number')
        .required("Password is required"),
      cPassword: Yup.string()
        .required("Confirm password Required"),
    }),
    onSubmit: (values) => {
      if (values?.password !== values?.cPassword) {
        toast.error("Confirm password doesn't match")
      } else {
        setLoading(true)

        resetPasswordMutation({
          variables: {
            password: values.password,
            code: new URLSearchParams(window.location.search).get('token')
          }
        }).then(({ data }) => {
          setLoading(false)
          if (data?.verifyForgotPassword) {
            validation.resetForm();
            toast.success("Password created successfully");
            openModal('LOGIN');
            window.location.href = window.location.origin;
          } else {
            toast.error("Password creation failed")
          }
        }).catch((error) => {
          setLoading(false)
          toast.error(error.message)
        })
      }
    }
  });

  return (

    <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light md:h-auto md:min-h-0 md:max-w-[530px] md:rounded-md">
      <div className="items-center p-10">
        <div className='mb-4 flex justify-center'>
          <img src={Logo} alt="logo" style={{ width: '250px' }} />
        </div>
        <div className='text-center'>
          <h3 className='text-lg mb-1 text-accent font-medium'>Create a New Password?</h3>
          <p className='text-md text-[#6B7280]'>Reset password with Procat360</p>
        </div>

        <Form className="tablelist-form" onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}>
          <div className='my-2'>
            <label className='text-sm mb-1' htmlFor="">Password</label>
            <div className='relative'>
            <Input
              name="password"
              className='mt-1 py-2.5 border-gray-300 item-center w-full overflow-hidden truncate rounded-md text-sm text-heading placeholder-gray-500 transition duration-300 ease-in-out focus:border-accent focus:outline-0 focus:ring-0'
              value={validation.values.password || ""}
                type={passwordShow ? "text" : "password"}
              placeholder="Confirm Password"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.password && validation.errors.password ? true : false
              }
            />
              {validation.touched.password && validation.errors.password ? (
                null
              ) : <div className="absolute right-[10px] top-[30%] cursor-pointer text-muted w-[18px]" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}>
                {passwordShow ? <EyeOff /> : <Eye />}
              </div>}
            </div>
            {validation.touched.password && validation.errors.password ? (
              <span className='text-[red] text-[13px] m-0'>{validation.errors.password}</span>
            ) : null}
          </div>
          <div className='my-2'>
            <label className='text-sm mb-1' htmlFor="">Confirm Password</label>
            <div className='relative'>
            <Input
              name="cPassword"
              className='mt-1 py-2.5 border-gray-300 item-center w-full overflow-hidden truncate rounded-md text-sm text-heading placeholder-gray-500 transition duration-300 ease-in-out focus:border-accent focus:outline-0 focus:ring-0'
              placeholder='Enter Confirm Password'
              value={validation.values.cPassword || ""}
                type={CpasswordShow ? "text" : "password"}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.cPassword && validation.errors.cPassword ? true : false
              }
            />
              {validation.touched.cPassword && validation.errors.cPassword ? (
                null
              ) : <div className="absolute right-[10px] top-[30%] cursor-pointer text-muted w-[18px]" id="password-addon" onClick={() => setCPasswordShow(!CpasswordShow)}>{CpasswordShow ? <EyeOff /> : <Eye />}</div>}
            </div>
            {validation.touched.cPassword && validation.errors.cPassword ? (
              <span className='text-[red] text-[13px] m-0'>{validation.errors.cPassword}</span>
            ) : null}
          </div>
          <button disabled={loading} type='submit' className='w-full bg-accent py-2.5 mt-7 text-md font-semibold shadow-700 transition-colors hover:bg-accent-hover focus:bg-accent-hover focus:outline-0 text-light rounded-md'>{loading ? 'Loading...' : 'Reset Password'}</button>
          <p className='mt-5 mb-0 text-center text-[14px]'>Back To <span className='text-accent underline cursor-pointer' onClick={() => { openModal('LOGIN') }}>Log In</span></p>
        </Form>
      </div>
    </div>
  )
}

export default CreateNewPassword