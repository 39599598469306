import { atom } from "recoil";

export const addToProjectAtom = atom({
  key: "addToProject",
  default: [],
});

export const addNewCustomFabricAtom = atom({
  key: "addNewCustomFabric",
  default: [],
});

export const selectedCustomFabricAtom = atom({
  key: 'selectedCustomFabric',
  default: { id: '' }
})

export const cropperSelectionAtom = atom({
  key: 'cropperSelection',
  default: {}
})

export const customBandValueAtom = atom({
  key: 'customBandValue',
  default: {}
})

export const shareSelectedFabricAtom = atom({
  key: 'shareSelectedFabric',
  default: ''
})

export const selectedBandAtom = atom({
  key: 'selectedBand',
  default: { id: '' }
})

export const aspectRationAtom = atom({
  key: 'aspectRation',
  default: {
    show: false,
    width: '',
    height: '',
    ratio: 1
  }
})

export const addToProjectImageAtom = atom({
  key: "addToProjectImage",
  default: [],
});
export const addFabricBeforeAtom = atom({
  key: "addFabricBefore",
  default: {},
});
export const offsetValueAtom = atom({
  key: "offsetValue",
  default: {
    Front: {
      x: 0,
      y: 0
    },
    Back: {
      x: 0,
      y: 0
    }
  },
});
export const addFabricToggleAtom = atom({
  key: "addFabricToggle",
  default: false,
});

export const canvasToggleRenderAtom = atom({
  key: "canvasToggleRender",
  default: [],
});

export const loaderAtom = atom({
  key: "loader",
  default: false,
});

export const uploadFabricAtom = atom({
  key: "uploadFabric",
  default: false,
});
export const selectedFabricBandAtom = atom({
  key: "selectedFabricBand",
  default: "",
});
// original
export const addBandFabricBeforeAtom = atom({
  key: "addBandFabricBefore",
  default: "",
});
// Selected
export const addBandFinalFabricBeforeAtom = atom({
  key: "addBandFinalFabricBefore",
  default: "",
});
// Selected all Band
export const selectedAllBandAtom = atom({
  key: "selectedAllBand",
  default: [],
});

export const boxDimentionAtom = atom({
  key: "boxDimention",
  default: {
    height: 409,
    width: 409
  },
});

export const fabricAtom = atom({
  key: "fabric",
  default: {
    currentPart: [],
    partWiseFabric: {},
  },
});

export const selectedFabricAtom = atom({
  key: "selectedFabric",
  default: {},
});
export const selectedImageScaletom = atom({
  key: "selectedImageScale",
  default: {
    Front: {
      Horizontal: 0,
      Vertical: 0,
      Scale: 1
    },
    Back: {
      Horizontal: 0,
      Vertical: 0,
      Scale: 1
    }
  },
});
export const beforeSelectedImageScaletom = atom({
  key: "beforeSelectedImageScale",
  default: {
    Front: {
      Horizontal: 0,
      Vertical: 0,
      Scale: 1
    },
    Back: {
      Horizontal: 0,
      Vertical: 0,
      Scale: 1
    }
  },
});

export const upholsteryItemAtom = atom({
  key: "upholsteryItem",
  default: {
    armStyle: {
      name: 'Track',
      img: 'Upholstery/Arm Style/track.png'
    },
    seatCushion: {
      name: 'C1 T-cush/21" dp/3',
      img: 'Upholstery/Seat cushion/C1 T-cush-21.png'
    },
    backPillow: {
      name: 'D16 BoxBorder /36h/3',
      img: 'Upholstery/Back Pillow/D16 BoxBorder-.png'
    },
    baseOrLeg: {
      name: 'E21 155L leg',
      img: 'Upholstery/Leg/E21 155L leg.png'
    },
    nailhead: {
      name: 'No',
      img: 'pillows-no.png'
    },
  }
})

export const upholsteryStatusAtom = atom({
  key: "upholsteryStatus",
  default: {
    armStyle: 1,
    seatCushion: 1,
    backPillow: 1,
    baseOrLeg: 1
  }
})

export const currentPillowStateAtom = atom({
  key: "currentPillowState",
  default: 'Double Banded Lumbar Pillow',
});

export const currentCustomizationAtom = atom({
  key: "currentCustomization",
  default: {
    color: "",
    pattern: "",
    size: {
      name: "12x20",
      image: "12x20.png",
      setSize: '12x20'
    },
    quantity: 1,
    border: {
      name: 'No',
      image: "pillows-no.png",
    },
    band: {
      name: 'No',
      image: "pillows-no.png",
      type: 'No'
    },
    insertType: {
      name: 'Pillow Cover Only',
      image: 'Pillow Cover Only.png'
    }
  },
});

export const tabAtom = atom({
  key: "tab",
  default: 'TAB_1',
});

export const uploadImageAtom = atom({
  key: "uploadImageAtom",
  default: {}
});

export const preFixAtom = atom({
  key: "preFix",
  default: 'app'
});

export const showModalAtom = atom({
  key: "showModal",
  default: false
});
export const showBandModalAtom = atom({
  key: "showBandModal",
  default: false
});

export const selectedAlphaAtom = atom({
  key: "selectedAlphaAtom",
  default: {
    name: 'Clear Wax',
    type: 'wax',
    image: 'https://customer.polynine.com/poc/suitepieces/fabric/vintage/Clear%20Wax.png',
    index: 1,
    exposure: 0.7,
    code: "42049056866556"
  },
});
export const selectedEdgeAtom = atom({
  key: "selectedEdge",
  default: [],
});

export const vintageAtom = atom({
  key: "vintage",
  default: {
    name: "Tall_Vintage_Dresser",
    handle: "Black Walnut Wood And Gold Ends TKnob",
    code: "31739265056827",
    sortName: 'Wood T-Knob',
    paint: "",
    finish: "",
    currentPart: ["Base"],
    image: 'handle_thumb/Black_Walnut_Wood_And_Gold_Ends_TKnob.jpg',
    // currentPart: ["Wood", "Bush", "MDF", "Base", "Drawer"],
    partWiseFabric: {},
  },
});

export const upholsteryAtom = atom({
  key: "upholstery",
  default: {
    name: "Kent Sofa",
    currentPart: ["Base", 'Seat_Cushion'],
    // currentPart: ["Base", "Seat", "Backrest", "Fabric", 'Arm_Style', 'Seat_Cushion', 'Back_Pillow'],
    partWiseFabric: {},
  },
});

export const drapryAtom = atom({
  key: "drapry",
  default: {
    name: "Drapery",
    size: '36" x 48"',
    sizeImage: "Drapery/Small window_select.png",
    currentPart: [],
    partWiseFabric: {},
  },
});

export const modelAtom = atom({
  key: "model",
  default: {
    categoryName: "",
    src: "",
    loading: 100
  },
});

export const pillowSideAtom = atom({
  key: "pillowSide",
  default: 'Front'
})
export const orderDetailsActionAtom = atom({
  key: "orderDetailsAction",
  default: {
    project: false, job: false, paymentRef: false, designer: false 
  }
})
export const orderDetailsDataAtom = atom({
  key: "orderDetailsData",
  default:{ project: "", job: "", paymentRef: "", designer: "" }
})
export const orderItemsActionAtom = atom({
  key: "orderItemsAction",
  default:{}
})
export const productListAtom = atom({
  key: "productList",
  default:[]
})
export const itemsListAtom = atom({
  key: "itemsList",
  default:[]
})