import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useRecoilState } from 'recoil';
import { addBandFabricBeforeAtom, addFabricBeforeAtom, canvasToggleRenderAtom, fabricAtom, selectedCustomFabricAtom, selectedFabricAtom, showBandModalAtom, showModalAtom, uploadFabricAtom, uploadImageAtom } from '../../state';

const MyDropzone = () => {
    const [showBandModal, setShowBandModal] = useRecoilState(showBandModalAtom)
    const [uploadFabricModel, setUploadFabricModel] = useRecoilState(uploadFabricAtom);
    const [addBandFabricBefore, setAddBandFabricBefore] = useRecoilState(addBandFabricBeforeAtom);
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles?.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = async () => {
                const binaryStr = reader.result
                setAddBandFabricBefore(binaryStr)
                setShowBandModal(true)
                setUploadFabricModel(false)
            }
            reader.readAsDataURL(file);
        })
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { 'image/*': [] } })

    return (
        <div {...getRootProps()}>
            <input id="image" type="file" className="dropzone" {...getInputProps()} />
            <div className="text-center cursor-pointer drop-zone d-flex flex-column justify-content-center">
                <div className="item-choose mx-2 mb-3">
                    <img src="Group 1786.png" id="image" className="img-fluid" alt='camo' />
                </div>
                <div className='color-gray'>
                    Drop your image here or
                    <span className='green-color'> browse</span>
                </div>
            </div>
        </div>
    )
}
export default MyDropzone;