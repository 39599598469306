import { Modal } from "react-bootstrap";
import Select from '../../components/select/select';
import { Input } from 'reactstrap'
import dayjs from 'dayjs';
import { Spin, DatePicker, Space } from "antd";

//formik
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useState } from "react";
import { UPDATE_ORDER_SUMMARY } from "../../apollo/gql/mutation/productSummary.mutation";
import { orderOption, paymentOption } from "../../lib/constant";
import moment from "moment";

function OrderDetailsEdit(props: any) {

  const itemData = props?.order || null;
  const [loading, setLoading] = useState(false);
  const [closed, setClosed] = useState<any>(itemData?.closed ? dayjs(itemData?.closed) : null);
  const [paidOrder, setPaidOrder] = useState<any>(itemData?.paidOrder ? dayjs(itemData?.paidOrder) : null);
  const [projectPending, setProjectPending] = useState<any>(itemData?.projectPending ? dayjs(itemData?.projectPending) : null);
  const [mutation] = useMutation(UPDATE_ORDER_SUMMARY)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      orderStatus: orderOption?.find((x: any) => x?.value === itemData?.orderStatus) || { label: 'Pending', value: 'pending' },
      paymentStatus: paymentOption?.find((x: any) => x?.value === itemData?.paymentStatus) || { label: 'Pending', value: 'pending' },
      paymentRef: itemData?.paymentRef || '',
      project: itemData?.project || '',
      job: itemData?.job || '',
      designer: itemData?.designer || '',
      projectPending: projectPending || '',
      paidOrder: paidOrder || '',
      closed: closed || '',
    },
    onSubmit: async (values) => {
      if (values?.paymentStatus || values?.orderStatus) {
        setLoading(true);

        const input = {
          _id: props?.order?._id,
          paymentStatus: values?.paymentStatus?.value,
          // orderStatus: values?.orderStatus?.value,
          paymentRef: values?.paymentRef,
          job: values?.job,
          project: values?.project,
          designer: values?.designer,
          projectPending: values?.projectPending || '',
          paidOrder: values?.paidOrder || '',
          closed: values?.closed || '',
        }

        mutation({ variables: { input: input } })
          .then((response) => {
            toast.success('Order updated');
            props?.refetch();
            props?.toggle();
            setLoading(false);
            validation.resetForm()
          }).catch((error) => {
            toast.error(error.message);
            setLoading(false);
          }
          )
      }
    },
  });

  return (
    <>
      <Modal
        show={props.visible}
        onHide={props.toggle}
        className="order-status-modal"
      >
        <Modal.Header closeButton>  <div className='items-center align-items-center flex flex-col w-100'>
          <h1 className='text-3xl font-medium mb-1'>Order Details</h1>
          <div>
            <p className="m-0 font-medium">Order #{itemData?.orderNumber}</p>
          </div>
        </div></Modal.Header>
        <Modal.Body className="m-0 p-0" style={{ width: '100%' }}>
          <div className="flex flex-col justify-center md:rounded-xl">
            <div className="items-center px-7 pt-0 py-8">
              <form autoComplete="off" className="tablelist-form" onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}>
                <div className='w-full'>

                  <div className='p-1 sm:p-5 bg-white' >
                    <div className='grid grid-cols-12 gap-4 py-2 ps-3 items-center text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-6'>
                        <span>Payment Status</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-6'>
                        <div className="mr-3">
                          <Select
                            className='mt-1 order-status-select'
                            name="paymentStatus"
                            options={paymentOption}
                            value={validation.values.paymentStatus}
                            onChange={(e) => {
                              validation.setFieldValue('paymentStatus', e)
                            }}
                            placeholder="Select Payment Status"
                            isSearchable={false}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='grid grid-cols-12 gap-4 py-2 ps-3 items-center text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-6'>
                        <span>Payment Reference#</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-6'>
                        <div className="mr-3">
                          <Input
                            name='paymentRef'
                            type="text"
                            value={validation.values.paymentRef || ""}
                            placeholder="Enter Payment Reference#"
                            onChange={validation.handleChange}
                            className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                          />
                        </div>
                      </div>
                    </div>


                    <div className='grid grid-cols-12 gap-4 py-2 ps-3 items-center text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-6'>
                        <span>Project</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-6'>
                        <div className="mr-3">
                          <Input
                            name='project'
                            type="text"
                            placeholder="Enter Project"
                            value={validation.values.project || ""}
                            onChange={validation.handleChange}
                            className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                          />
                        </div>
                      </div>
                    </div>


                    <div className='grid grid-cols-12 gap-4 py-2 ps-3 items-center text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-6'>
                        <span>Job</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-6'>
                        <div className="mr-3">
                          <Input
                            name='job'
                            type="text"
                            value={validation.values.job || ""}
                            placeholder="Enter Job"
                            onChange={validation.handleChange}
                            className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                          />
                        </div>
                      </div>
                    </div>


                    <div className='grid grid-cols-12 gap-4 py-2 ps-3 items-center text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-6'>
                        <span>Designer</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-6'>
                        <div className="mr-3">
                          <Input
                            name='designer'
                            type="text"
                            placeholder="Enter Designer"
                            value={validation.values.designer || ""}
                            onChange={validation.handleChange}
                            className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px]  w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='grid grid-cols-12 gap-4 py-2 ps-3 items-center text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-6'>
                        <span>Project Pending</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-6'>
                        <div className="mr-3">
                          <Space direction="vertical" className="w-full">
                            <DatePicker
                              name='projectPending'
                              id="closedInputBox"
                              value={projectPending}
                              format="MM-DD-YYYY"
                              onChange={(date: any, dateString: any) => {
                                if (dateString) {
                                  const newDate = moment(dateString, "MM-DD-YYYY").format("YYYY-MM-DD")
                                  setProjectPending(newDate ? dayjs(newDate) : null)
                                } else {
                                  setProjectPending(null)
                                }
                              }}
                              className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px] w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                            />
                          </Space>
                        </div>
                      </div>
                    </div>
                    <div className='grid grid-cols-12 gap-4 py-2 ps-3 items-center text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-6'>
                        <span>Paid Order</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-6'>
                        <div className="mr-3">
                          <Space direction="vertical" className="w-full">
                            <DatePicker
                              name='paidOrder'
                              id="closedInputBox"
                              value={paidOrder}
                              format="MM-DD-YYYY"
                              onChange={(date: any, dateString: any) => {
                                if (dateString) {
                                  const newDate = moment(dateString, "MM-DD-YYYY").format("YYYY-MM-DD")
                                  setPaidOrder(newDate ? dayjs(newDate) : null)
                                } else {
                                  setPaidOrder(null)
                                }
                              }}
                              className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px] w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                            />
                          </Space>
                        </div>
                      </div>
                    </div>
                    <div className='grid grid-cols-12 gap-4 py-2 ps-3 items-center text-[15.5px] font-[400] '>
                      <div className='col-span-6 sm:col-span-6'>
                        <span>Closed</span><br />
                      </div>
                      <div className='col-span-6 sm:col-span-6'>
                        <div className="mr-3">
                          <Space direction="vertical" className="w-full">
                            <DatePicker
                              name='closed'
                              id="closedInputBox"
                              value={closed}
                              format="MM-DD-YYYY"
                              onChange={(date: any, dateString: any) => {
                                if (dateString) {
                                  const newDate = moment(dateString, "MM-DD-YYYY").format("YYYY-MM-DD")
                                  setClosed(newDate ? dayjs(newDate) : null)
                                } else {
                                  setClosed(null)
                                }
                              }}
                              className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] h-[40px] p-[6px] w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0'
                            />
                          </Space>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className='w-full flex justify-center mt-10'>
                    <button disabled={loading} type='submit' className='flex items-center justify-center rounded border-black border-[1.5px] py-2.5 px-10 text-[12px] font-medium bg-black text-white lg:text-base me-4'>Save {loading && <Spin className="submit-loader" />}</button>
                    <button disabled={loading} type='reset' onClick={() => {
                      props?.toggle()
                      validation.resetForm()
                    }} className='flex items-center justify-center rounded border-black border-[2px] py-2.5 px-8 text-[12px] font-medium  text-black lg:text-base'>Cancel</button>
                  </div>
                </div>
              </form>


            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
}
export default OrderDetailsEdit;