import React from 'react'
import { useModalAction } from '../modal/modal.context';
import Select from '../select/select';
import { CloseIcon } from '../../icons/close-icon';
import { Table } from '../table';

const OrderStatus = () => {
  const { closeModal } = useModalAction();

  const orderOption = [
    { label: 'Pending', value: 'pending' },
    { label: 'Completed', value: 'completed' },
    { label: 'Cancel', value: 'cancel' },
  ]

  const orderTableColumns = [
    {
      title: 'No.',
      dataIndex: '',
      key: 'items',
      align: 'center',
      width: 50,
      ellipsis: true,
      render: function renderQuantity(pivot: any) {
        return <span>01</span>;
      }
    },
    {
      title: "Order  Status",
      dataIndex: 'pivot',
      key: 'pivot',
      align: 'center',
      width: 200,
      render: function renderQuantity(pivot: any) {
        return <span>Project Pending</span>;
      },
    },
    {
      title: "",
      dataIndex: 'pivot',
      key: 'pivot',
      align: 'center',
      width: 250,
      render: function renderQuantity(pivot: any) {
        return <div>
          <input type="text" value="$ 234" className='rounded-[4px] border-gray-300 text-[15.5px] font-[400] text-gray-500 w-full focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
        </div>;
      },
    },
  ];

  return (<div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light md:h-auto md:min-h-0 md:max-w-[750px] md:rounded-xl">
    <div onClick={() => { closeModal(); }} className='w-full lg:flex justify-end pt-4 pr-4 hidden cursor-pointer'><CloseIcon width='20px' /></div>
    <div className="items-center px-7 py-10">

      <div className='text-center mb-5 items-center flex flex-col'>
        <h1 className='text-3xl font-semibold mb-1'>Order Status</h1>
      </div>

      <div className='mt-10 w-full'>
        <span className='text-[15px] font-medium'>Changes Status</span>
        <Select
          className='mt-1'
          name="type"
          options={orderOption}
          placeholder="Select Type"
          isSearchable={false}
        />

        <div className='mt-5'>
          <Table
            //@ts-ignore
            columns={orderTableColumns}
            data={[{}, {}, {}, {}]}
            rowKey={(record: any) =>
              record.pivot?.variation_option_id
                ? record.pivot.variation_option_id
                : record.created_at
            }
            className="orderDetailsTable w-full"
            rowClassName="!cursor-auto"
            scroll={{ x: 350, y: 500 }}
          />
        </div>

        <div className='w-full flex justify-center mt-10'>
          <button className='flex items-center justify-center rounded border-accent border-[1.5px] py-2.5 px-9 text-[12px] font-medium bg-accent text-white lg:text-base me-4'>Save</button>
          <button className='flex items-center justify-center rounded border-accent border-[2px] py-2.5 px-7 text-[12px] font-medium  text-accent lg:text-base'>Cancel</button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default OrderStatus