import CheckIcon from "../icons/check-icon";
import { CloseIcon } from "../icons/close-icon";

export const base64ToBlob = (base64String, contentType,) => {
  const base64WithoutPrefix = base64String.includes(',') ? base64String.split(',')[1] : base64String;
  const byteCharacters = atob(base64WithoutPrefix);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};

export const OrderInputAction = ({ handleSave, handleCancel, inputName, index }) => {
  return <div className='flex w-2/3 mt-1'>
    <button onClick={() => handleSave(inputName)} style={{ boxShadow: 'rgba(99, 99, 99, 0.3) 0px 2px 8px 0px' }} className=' rounded-[2px] p-1.5 bg-white'><CheckIcon width="25px" height="25px" /></button>
    <button onClick={() => handleCancel(inputName, index)} style={{ boxShadow: 'rgba(99, 99, 99, 0.3) 0px 2px 8px 0px' }} className=' rounded-[2px] p-1.5 ms-2 bg-white'><CloseIcon width="24px" height="24px" /></button>
  </div>
}

export const isUserAdmin = () => {
  const userData = localStorage?.getItem("userData")
  const user = userData && JSON.parse(userData);
  return user?.isAdmin;
}

export const ScrollTable = () => {
  const className = '.table-responsive'
  const slider = document.querySelector(className);
  let mouseDown = false;
  let startX
  let scrollLeft
  // let thdragg = false
  let startDragging = function (e) {
    if (((typeof e?.target?.className) === "string") && !(e?.target?.className?.split(' ')?.includes("for-scroll-stop"))) {
      mouseDown = true;
      // thdragg = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    }

  };
  let stopDragging = function () {
    mouseDown = false;
    slider.classList.remove('active');
  };
  slider.addEventListener('mousemove', (e) => {
    if (!mouseDown) { return; }
    const x = e.pageX - slider.offsetLeft;
    const scroll = x - startX;
    slider.scrollLeft = scrollLeft - scroll;
  });

  // Add the event listeners
  slider.addEventListener('mousedown', startDragging, false);
  slider.addEventListener('mouseup', stopDragging, false);
  slider.addEventListener('mouseleave', stopDragging, false);
}