import React from 'react'
import { useModalAction } from '../modal/modal.context';
import mailIcon from "../../assets/icon/mail.png"
import { useMutation } from '@apollo/client';
import { RESEND_EMAIL, VERIFY_EMAIL } from '../..//apollo/gql/query/user.query';
import { toast } from 'react-toastify';

const EmailConfirmation = () => {


  const { openModal, closeModal } = useModalAction();

  const [verifyEmailMutation] = useMutation(VERIFY_EMAIL)
  const [resendEmailMutation] = useMutation(RESEND_EMAIL)

  const tempToken = localStorage?.getItem('tempToken');
  const userDataJSON = localStorage.getItem('userData');
  const userData = userDataJSON ? JSON.parse(userDataJSON) : null;

  const onVerifyEmail = () => {
    // verifyEmailMutation({ variables: { token: tempToken } })
    //   .then((res) => { toast.success('Email verified successfully.'); openModal('LOGIN'); router.push('/'); localStorage?.removeItem('tempToken'); })
    //   .catch((err) => { toast.error(err?.message); })
  }

  const handleResendEmail = () => {
    const input = {
      email: userData?.email,
      domain: window.location.origin
    }
    resendEmailMutation({ variables: { input } })
      .then((response) => {
        toast.success('Verification link sent successfully')
        const user = { ...userData, token: response?.data?.resendStoreFrontEmailVerificationLink }
        localStorage.setItem('userData', JSON?.stringify(user))
      }).catch((error) => {
        toast.error(error.message)
      }
      )
  }

  return (

    <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light md:h-auto md:min-h-0 md:max-w-[530px] md:rounded-md">
      <div className="items-center p-10">
        <div className='mb-5 flex justify-center'>
          <img src={mailIcon} alt="mailIcon" style={{ width: '100px' }} />
        </div>
        <div className='text-center mb-3'>
          <h3 className='md:text-xl text-lg mb-2 font-medium'>Email Confirmation</h3>
          <p className='text-muted'>We have sent email to <span className='text-[#6C80FE]'>{userData?.email}</span> to
            confirm the validity of our email Address. After receiving
            the email follow the link provided to complete your registration.
          </p>
        </div>
        <hr />
        <p className='mt-3 text-center text-[15px] text-muted'>If you not got any mail <span className='text-[#6C80FE] cursor-pointer' onClick={handleResendEmail}>Resend confirmation mail</span></p>
      </div>
    </div>
  )
}

export default EmailConfirmation