//apollo
import { useState } from 'react';
import { formatUTCDate, progressStatus } from '../../lib/utils/utils';
import { useRecoilState } from 'recoil';
import { itemsListAtom, orderDetailsActionAtom, orderDetailsDataAtom, orderItemsActionAtom, productListAtom } from '../../state';
import { orderOption } from '../../lib/constant';

const MyOrderCard = ({ productSummary, setOrder }: any) => {

  const [selectedCard, setSelectedCard] = useState<any>(productSummary[0]);
  const [productList, setProductList] = useRecoilState<any>(productListAtom)
  const [itemsList, setItemsList] = useRecoilState<any>(itemsListAtom)
  const [orderAction, setOrderAction] = useRecoilState<any>(orderItemsActionAtom);
  const [orderDetailsAction, setOrderDetailsAction] = useRecoilState<any>(orderDetailsActionAtom);
  const [orderData, setOrderData] = useRecoilState<any>(orderDetailsDataAtom);

  const handleClick = (summary: any) => (event: React.MouseEvent<HTMLDivElement>) => {
    setSelectedCard(summary?._id === selectedCard?._id ? null : summary);
    setOrder(summary);
    setOrderData({ project: "", job: "", paymentRef: "", designer: "" })
    setOrderAction({})
    setProductList(itemsList)
    setOrderDetailsAction({ project: false, job: false, paymentRef: false, designer: false });
  }

  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      {productSummary?.map((summary: any, index: number) => {

        return <div className="p-0.5 bg-gray-100 rounded-[5px] flex-col justify-center items-start w-full my-3 cursor-pointer" onClick={handleClick(summary)} style={{ border: selectedCard ? summary?._id === selectedCard?._id ? '2px solid #000' : '2px solid transparent' : index === 0 ? '2px solid #000' : '2px solid transparent' }}>
          <div className="px-4 pt-3 pb-[13px] border-b border-gray-200 xl:flex lg:block flex justify-between items-center" style={{ borderBottom: '1px solid rgba(229, 231, 235)' }}>
            <div className="self-stretch justify-center items-center inline-flex">
              <h4 className="text-gray-800 text-[15px] sm:text-[16px] font-bold m-0">Order</h4>
              <p className="m-0 justify-center items-center text-gray-800 text-[13px] sm:text-[15.12px]">#{summary?.orderNumber || ''}</p>
            </div>
            <p className="text-yellow-600 text-sm font-medium whitespace-nowrap m-0 bg-yellow-600 px-3 py-2 bg-opacity-[.15]">{orderOption?.find(x => x?.value === progressStatus(summary?.productSummary, ""))?.label || "Pending"}</p>
          </div>
          <div className="px-4 py-4">

            <div className="pb-[13px] xl:flex lg:block flex justify-between items-center">
              <p className="text-[15.12px] m-0"><span className='w-[110px]'>Order Date </span></p>
              <p className="text-[15.12px] xl:text-end lg:text-start xs:text-end m-0">{formatUTCDate(summary?.createdAt)}</p>
            </div>

            <div className="pb-[13px] xl:flex lg:block flex justify-between items-center">
              <p className="text-[15.12px] m-0"><span className='w-[110px]'>Name </span></p>
              <p className="text-[15.12px] xl:text-end lg:text-start xs:text-end m-0">{summary?.name || ''}</p>
            </div>

            <div className="pb-[13px] xl:flex lg:block flex justify-between items-center">
              <p className="text-[15.12px] font-semibold m-0"><span className='w-[110px]'>Total Price </span></p>
              <p className="text-[15.12px] font-semibold xl:text-end lg:text-start xs:text-end m-0">${summary?.totalPrice?.toFixed(2) || 0}</p>
            </div>

          </div>
        </div >
      })}
    </div>

  );

};
export default MyOrderCard;
