import FabricSelection from "./FabricSelection";
import { GET_URL } from "../../../constans";
import { armStyleData, backPillowData, baseOrLegData, material, nailheadData, seatCushionData, subMaterial } from '../../../lib/constant';
import { UpholsteryData } from "../../../content";
// import {UpholsteryData}
export const UpholsteryTabData = (
    tab,
    upholsteryAtomState,
    setFabricImg,
    upholsteryItem,
    setUpholsteryItem,
    upholsteryStatus,
    setUpholsteryStatus,
    modelAtomValue,
    setModelAtomValue
) => {
    const { armStyle, backPillow, baseOrLeg, nailhead, seatCushion } = upholsteryItem
    const selectHandler = (key, index, data) => {
        setUpholsteryItem({
            ...upholsteryItem,
            [key]: data
        })
        const newData = {
            ...upholsteryStatus,
            [key]: index + 1
        }
        setUpholsteryStatus(newData)
        const output = newData.armStyle.toString() + newData.seatCushion.toString() + newData.backPillow.toString() + newData.baseOrLeg.toString();
        setModelAtomValue({
            ...modelAtomValue,
            src: UpholsteryData[output]
        })
    }

    const renderItem = (title, key, selected, allData) => {
        return <div className="p-4">
            <div className="border-bottom mb-4 w-83 mobile-none">
                <p className="text-color font-17">{title}</p>
                <div className="d-flex align-items-center pb-3">
                    <div className="small-box ">
                        <img src={selected?.img} style={{ width: '100%', }} alt="" />
                    </div>
                    <div className=" ms-2 mb-0">
                        <p className="font-14 text-black mb-0">
                            {selected?.name || 'Plain Fabric'}
                        </p>
                    </div>
                </div>
            </div>
            <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                {allData?.map((data, index) => {
                    return <div style={{ marginRight: "4%" }} key={data?.name}>
                        <div
                            className={selected?.name === data?.name ? 'cursor-pointer choose-card active' : 'auto-border-sofa cursor-pointer'}
                            style={{ height: 110 }}
                            onClick={() => selectHandler(key, index, data)}
                        >
                            <img src={data?.img} alt="" className="h-100" />
                        </div>
                        <p style={{ maxWidth: 110, fontSize: 14 }}>{data?.name}</p>
                    </div>
                })}
            </div>
            {key === 'baseOrLeg' && <>
                <FabricSelection
                    materialCategory={material.legWood}
                    subMaterialCategory={subMaterial.legWood}
                    header="UPHOLSTERY"
                    setFabric={setFabricImg}
                />
            </>}
        </div>
    }

    switch (tab) {
        case "TAB_1":
            return (
                <div className="p-4">
                    <div className="border-bottom w-100">
                        <p className="text-color font-17">FABRIC</p>
                        <div className="d-flex align-items-center pb-3">
                            <div className="small-box ">
                                {upholsteryAtomState?.partWiseFabric?.Base?.finishThumb ?
                                    <img src={GET_URL(upholsteryAtomState?.partWiseFabric?.Base?.finishThumb)} alt="" />
                                    :
                                    <img src={'defalutImage.jpg'} alt="" />
                                }
                            </div>
                            <p
                                style={{ marginLeft: "10px", marginTop: "20px" }}
                                className="font-14"
                            >
                                <span className="color-gray">{upholsteryAtomState?.name} </span><br />
                                {upholsteryAtomState?.partWiseFabric?.Base?.name || 'Plain Fabric'}
                            </p>
                        </div>
                    </div>
                    <FabricSelection
                        materialCategory={material.pillowFabric}
                        subMaterialCategory={subMaterial.upholsteryFabric}
                        header="UPHOLSTERY"
                        setFabric={setFabricImg}
                    />
                </div>
            );
        case "TAB_2":
            return renderItem('ARM STYLE', 'armStyle', armStyle, armStyleData)
        case "TAB_3":
            return renderItem('SEAT CUSHION', 'seatCushion', seatCushion, seatCushionData)
        case "TAB_4":
            return renderItem('BACK PILLOW', 'backPillow', backPillow, backPillowData)
        case "TAB_5":
            return renderItem('BASE OR LEG', 'baseOrLeg', baseOrLeg, baseOrLegData)
        case "TAB_6":
            return (
                <div className="p-4">
                    <div className="border-bottom mb-4 w-83 mobile-none">
                        <p className="text-color font-17">NAILHEADS</p>
                    </div>
                    <div className="d-flex ">
                        {nailheadData?.map(data => {
                            return <div className={`side-box me-3 ${data?.name === nailhead?.name ? 'active' : ''} position-relative`} key={data?.name}
                            // onClick={() => selectHandler('nailhead', data)}
                            >
                                <img src={data?.img} alt="" className="side-img cursor-pointer" />
                                <p className="font-14 text-black text-center mb-0">{data?.name}</p>
                            </div>
                        })}
                    </div>
                </div>
            );
    }
}