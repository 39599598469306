export enum PaymentStatus {
  PENDING = 'payment-pending',
  PROCESSING = 'payment-processing',
  SUCCESS = 'payment-success',
  FAILED = 'payment-failed',
  REVERSAL = 'payment-reversal',
  COD = 'cash-on-delivery',
  AWAITING_FOR_APPROVAL = 'payment-awaiting-for-approval',
}


// export const ORDER_STATUS = [
//   { name: 'Order Pending', status: 'order-pending', serial: 1 },
//   { name: 'Order Processing', status: 'order-processing', serial: 2 },
//   {
//     name: 'Order At Workroom',
//     status: 'order-at-workroom',
//     serial: 3,
//   },
//   {
//     name: 'Ready For Pickup',
//     status: 'ready-for-pickup',
//     serial: 4,
//   },
//   { name: 'Order Closed', status: 'order-closed', serial: 5 },
// ];

export const ORDER_STATUS = [
  { name: 'Pending', status: 'pending', serial: 1 },
  { name: 'Paid Order', status: 'paidOrder', serial: 2 },
  { name: 'Ordered w/Vendor', status: 'vendorOrder', serial: 3 },
  { name: 'Shipped', status: 'shipped', serial: 4 },
  { name: 'Received', status: 'received', serial: 5 },
  { name: 'Sent to Workroom', status: 'sentToWorkroom', serial: 6 },
  { name: 'Ready for Pickup', status: 'readyForPickup', serial: 7 },
  { name: 'Closed', status: 'closed', serial: 8 },
]

export const ORDER_USER_STATUS = [
  { name: 'Pending', status: 'pending', serial: 1 },
  { name: 'Order Processing', status: 'paidOrder', serial: 2 },
  { name: 'Order At Workroom', status: 'sentToWorkroom', serial: 3 },
  { name: 'Ready For Pickup', status: 'readyForPickup', serial: 4 },
]

export const filterOrderStatus = (
  orderStatus: any[],
  currentStatusIndex: number
) => {

  return orderStatus
};
