import { useMutation } from "@apollo/client";
import { Spin } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import {
    ImageGlobalUrl, addVariant, shopifyDomain, shopifyDomainProd, shopifyToken, shopifyTokenProd, addVariantProd,
    addVariantPillowId, addVariantPillowProdId, addVariantVintageId, addVariantVintageProdId, removeVariant, removeVariantProd
} from "../../../lib/constant";
import { Notification } from "../../../lib/Notification";
import { preFixAtom } from "../../../state";
import { UPDATE_PROJECT_SUMMARY } from "../graphQl/Mutation";

const AccessLinkPage = ({ setModalShow, uniqueCode, setUniqueCode, productSummary }) => {
    const copyUrl = `${window.location.origin}/project/${uniqueCode}`
    const hostName = window.location.host === 'design.suitepieces.com'
    const [linkCopy, setLinkCopy] = useState(false)
    const [loading, setLoading] = useState(false)
    const [updateProjectMutation] = useMutation(UPDATE_PROJECT_SUMMARY)
    const preFix = useRecoilValue(preFixAtom);
    // const [addVariantMutation] = useMutation(ADD_VARIANT)
    const copyLink = () => {
        var text = copyUrl
        var elem = document.createElement("textarea");
        document.body.appendChild(elem);
        elem.value = text;
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);
        toast.success(Notification['Copy Link'])
        setLinkCopy(true)
    }

    const submitPlaceHolder = async () => {
        const newSummary = await Promise.all(productSummary?.productSummary?.map(async data => {
            const input = {
                "variantTitle": `${data?.seriesName} ${data?.name} ${productSummary?.uniqueCode}`,
                "variantPrice": data?.sumCalculate || 0,
                "variantQuantity": 50,
                "imageSrc": `${ImageGlobalUrl.replace('app', preFix)}/${data?.base64Image}`
                // productId: hostName ? addVariantProdId : addVariantId
            };
            // addVariantMutation({ variables: { data: input } })
            //     .then((res) => {
            //         console.log("🚀 ~ file: AccessLinkPage.js:41 ~ .then ~ data:", res)

            //     }).catch(err => {
            //         console.log("🚀 ~ file: AccessLinkPage.js:64 ~ .then ~ err:", err)
            //     })
            let fullUrl
            let removeUrl
            if (hostName) {
                fullUrl = `${addVariantProd}${data?.type === "pillow" ? addVariantPillowProdId : addVariantVintageProdId}`
                removeUrl = `${removeVariantProd}${data?.type === "pillow" ? addVariantPillowProdId : addVariantVintageProdId}`
            } else {
                fullUrl = `${addVariant}${data?.type === "pillow" ? addVariantPillowId : addVariantVintageId}`
                removeUrl = `${removeVariant}${data?.type === "pillow" ? addVariantPillowId : addVariantVintageId}`
            }
            try {
                await axios.delete(removeUrl);
            } catch (error) {
                console.log("Error:", error);
            }

            try {
                const response = await axios.post(fullUrl, input);
                return {
                    ...data,
                    variantId: response?.data?.addedVariant?.id
                };
            } catch (error) {
                console.log("Error:", error);
                throw error;
            }
        }));
        const client = window.ShopifyBuy.buildClient({
            domain: hostName ? shopifyDomainProd : shopifyDomain,
            storefrontAccessToken: hostName ? shopifyTokenProd : shopifyToken,
        })
        client.checkout.create().then(async function (checkout) {
            const checkoutID = checkout.id;
            client.checkout.fetch(checkoutID) // Replace with the actual checkout ID
                .then(cart => {
                    return client.checkout.updateAttributes(cart.id, { note: copyUrl });
                })
                .then(updatedCart => {
                    console.log('Cart updated successfully:', updatedCart);
                })
                .catch(error => {
                    console.error('Error updating cart:', error);
                });
            let checkoutArray = []
            for (const item of newSummary) {
                try {
                    if (item?.type === "pillow") {
                        const variantId = `gid://shopify/ProductVariant/${item?.variantId}`;
                        const quantity = item?.quantity;
                        checkoutArray = [...checkoutArray, { variantId, quantity }]
                        // const updatedCheckout = await client.checkout.addLineItems(checkoutID, [{ variantId, quantity }]);
                    } else {
                        const parts = item?.parts || [];
                        for (const partName of parts) {
                            const varId = partName?.code;
                            if (varId) {
                                const variantId = `gid://shopify/ProductVariant/${varId}`;
                                const quantity = partName?.quantity || 1;
                                checkoutArray = [...checkoutArray, { variantId, quantity }]
                                // await client.checkout.addLineItems(checkoutID, [{ variantId, quantity }]);
                            }
                        }
                    }

                } catch (error) {
                    console.error('Error adding line items:', error);
                }
            }
            await client.checkout.addLineItems(checkoutID, checkoutArray);

            let sortedProductList = [...newSummary]
            sortedProductList.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateB.getTime() - dateA.getTime();
            });

            const input = {
                _id: productSummary?._id,
                productSummary: sortedProductList,
            }
            updateProjectMutation({ variables: { input: input } })
                .then(() => {
                    var a = document.createElement("a");
                    a.href = checkout.webUrl;
                    document.body.appendChild(a);
                    a.click();
                    setLoading(false)
                }).catch(err => {
                    console.log("🚀 ~ file: AccessLinkPage.js:64 ~ .then ~ err:", err)
                    toast.error(err)
                    setLoading(false)
                })

        });

    }

    return (
        <Modal
            show={true}
            onHide={() => {
                setUniqueCode('')
                setModalShow(false)
            }}
            className="project-saved-modal"
        >
            <Modal.Header closeButton></Modal.Header>
            {uniqueCode && <Modal.Body style={{ width: '74%' }}>
                <div>
                    <div className="text-center">
                        <img src="modal-ty.png" alt="" className="img-fluid modal-ty m-auto" />
                        <h2 className="title mb-2 pt-2 mt-3">Your Project is saved!</h2>
                        <h5 className="sub-title mb-5">
                            Use this link to access your project.
                        </h5>
                    </div>
                    <div className=" w-100">
                        <div className="mb-3 d-flex align-items-center flex-wrap">
                            <label style={{ flex: "1" }} className="w-100  me-2 label-input" >
                                <input
                                    type="text"
                                    name="name"
                                    className="w-100 copy-link"
                                    value={copyUrl}
                                    readOnly
                                />
                            </label>
                            <button className="btn btn-outline-primary " onClick={() => copyLink()}>{linkCopy ? 'Copied!' : 'Copy'}</button>
                        </div>
                    </div>
                    <a
                        href={copyUrl}
                        target='_blank'
                        className="mt-2 mb-3 preview-link text-left text-dark me-auto w-100"
                    >
                        Preview link in new tab
                    </a>
                    <div className="text-center mt-3 pt-2">
                        <div className="row">
                            {productSummary?.productSummary?.map((image, index) => {
                                return <div className="col-3" key={index}>
                                    <img
                                        src={`${ImageGlobalUrl.replace('app', preFix)}/${image?.base64Image}`}
                                        key={image?.base64Image}
                                        alt=""
                                        className="img-fluid "
                                    />
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="d-flex justify-center w-100">
                        <button className="btn btn-primary mt-3" style={{ maxWidth: 173 }} onClick={() => {
                            if (!loading) {
                                setLoading(true)
                                submitPlaceHolder()
                            }
                        }}>Place order {loading && <Spin className="submit-loader" />}</button>

                    </div>

                </div>
            </Modal.Body>
            }
        </Modal>
    )
}

export default AccessLinkPage