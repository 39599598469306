/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
// import "@google/model-viewer";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  addToProjectAtom,
  addToProjectImageAtom,
  modelAtom,
  preFixAtom,
  upholsteryAtom,
  upholsteryItemAtom,
} from "../../state";
import { API_ENDPOINT, GET_URL } from "../../constans";
const textures = {};

const UpholsteryModelViewer = ({ showButton }) => {
  const modelViewer = document.querySelector("#model-viewer")
  const upholstery = useRecoilValue(upholsteryAtom);
  const upholsteryItem = useRecoilValue(upholsteryItemAtom)
  const [modelAtomValue, setModelAtomValue] = useRecoilState(modelAtom);
  const preFix = useRecoilValue(preFixAtom);
  const [addToProject, setAddToProject] = useRecoilState(addToProjectAtom);
  const [addToProjectImage, setAddToProjectImage] = useRecoilState(addToProjectImageAtom);
  const getCheckSupport = localStorage.getItem('checkSupport')

  useEffect(() => {
    modelAtomValue.src.replace('app', preFix) && preFix && getUVModel();
  }, [upholstery.partWiseFabric, modelAtomValue.src]);

  useEffect(() => {
    updateMaterial();
  }, [upholstery.partWiseFabric, modelAtomValue.src]);


  const getUVModel = async () => {
    try {
      if (modelViewer?.model) {
        const model = modelViewer.model;

        let keys = Object.keys(upholstery.partWiseFabric)
        if (keys.length === 0) {
          return;
        }

        setModelAtomValue({
          ...modelAtomValue,
          loading: 0,
        });
        if (!model) return;
        const parts = model[Object.getOwnPropertySymbols(model)[1]];
        let meterialIndexies = [];

        for (const key of keys) {
          // eslint-disable-next-line no-loop-func
          parts.forEach((part) => {
            if (key === part.name) {
              const index = part.initialMaterialIdx
              meterialIndexies = {
                ...meterialIndexies,
                [key]: index,
              };
            }
          });
        }
        for (const fabricKey of keys) {
          if (typeof meterialIndexies[fabricKey] === 'number' && isFinite(meterialIndexies[fabricKey])) {
            const material = modelViewer.model.materials[meterialIndexies[fabricKey]];
            const diffuse_map = material.pbrMetallicRoughness['baseColorTexture'].texture.sampler;
            const scale = {
              u: Number(upholstery.partWiseFabric[fabricKey].repeatX),
              v: Number(upholstery.partWiseFabric[fabricKey].repeatY),
            };
            const offset = {
              u: Number(upholstery.partWiseFabric[fabricKey].offsetX),
              v: Number(upholstery.partWiseFabric[fabricKey].offsetY),
            }
            //   rotate: upholstery.partWiseFabric[fabricKey].rotate,
            const normal_map = material.normalTexture.texture.sampler;
            const metallic_map = material.pbrMetallicRoughness['metallicRoughnessTexture'].texture.sampler;
            // set scale
            diffuse_map.setScale(scale);
            normal_map.setScale(scale);
            metallic_map.setScale(scale);
            // set offset
            diffuse_map.setOffset(offset);
            normal_map.setOffset(offset);
            metallic_map.setOffset(offset);
          }
        }
        // document.querySelector("#model-viewer").setAttribute("src", oldSrc);
      }
    } catch (error) {
    }
  };

  const createAndApplyTexture = async (channel, modelPartName, fabricImg, mergeImage) => {
    const base64Image = fabricImg?.search('data:image/png;base64') !== -1 ? true : false
    let imageConvert = fabricImg
    if (getCheckSupport === 'true' && window.webpSupport && !base64Image) {
      imageConvert = fabricImg?.replaceAll('.jpeg', '.webp')
    }
    try {
      if (modelViewer?.model) {
        let texture = null;
        if (textures[fabricImg]) {
          texture = textures[fabricImg];
        } else {
          texture = await modelViewer.createTexture(base64Image ? fabricImg : GET_URL(imageConvert));
          textures[fabricImg] = texture;
        }

        const model = modelViewer.model;
        const parts = model[Object.getOwnPropertySymbols(model)[1]];

        const meterialIndexies = [];
        const partWiseIndex = {};

        parts.forEach((part) => {
          const index = part.initialMaterialIdx
          partWiseIndex[index] = part.name;
          if (modelPartName === part.name) {
            meterialIndexies.push(index);
          }
        });

        meterialIndexies.forEach(index => {
          const material = modelViewer.model.materials[index];
          const pbrMR = material.pbrMetallicRoughness;

          if (channel.includes("base") || channel.includes("metallic")) {
            pbrMR[channel].setTexture(texture);
          } else {
            material[channel].setTexture(texture);
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateMaterial = async () => {
    let keys, material;
    keys = Object.keys(upholstery.partWiseFabric);
    material = upholstery;

    if (keys.length === 0) {
      return;
    }
    for (const key of keys) {
      if (material.partWiseFabric[key]) {
        await createAndApplyTexture(
          "baseColorTexture",
          key,
          material.partWiseFabric[key].diffuse
        );
        await createAndApplyTexture(
          "metallicRoughnessTexture",
          key,
          material.partWiseFabric[key].metallicRoughness
        );
        await createAndApplyTexture(
          "normalTexture",
          key,
          material.partWiseFabric[key].normal
        );
      }
    }

  };

  if (modelViewer) {
    modelViewer.addEventListener("load", (data) => {
      updateMaterial();
    });
  }

  const addToProjectHandler = () => {
    document.querySelector('model-viewer').fieldOfView = 10
    document.querySelector("#model-viewer").cameraOrbit = '0deg 75deg 90%'
    setTimeout(() => {
      const base64Image = document.querySelector("#model-viewer").toDataURL('image/png')
      const cehckIndex = addToProject?.length ? Number(addToProject?.[0]?.seriesName?.slice(1)) : 0
      let newPaint = {}
      let paintName = {
        Base: '',
        Legs: ''
      }

      for (let key in upholstery?.partWiseFabric) {
        const value = upholstery?.partWiseFabric[key]
        newPaint = { ...newPaint, [key]: value?._id }
        paintName = { ...paintName, [key]: value?.name }

      }

      const updateFabric = {
        ...upholstery,
        partWiseFabric: newPaint
      }
      const addData = {
        name: updateFabric?.name,
        type: 'upholstery',
        quantity: 1,
        base64Image: base64Image,
        fabric: updateFabric,
        modelAtomValue: {
          ...modelAtomValue,
          src: document.querySelector("#model-viewer").src || modelAtomValue.src
        },
        upholsteryItem,
        paintName,
        seriesName: cehckIndex + 1 > 9 ? `S${cehckIndex + 1}` : `S0${cehckIndex + 1}`
      }
      let checkData
      const checkSameName = addToProject?.filter(data => data?.name === updateFabric?.name)

      if (checkSameName?.length > 0) {
        checkData = addToProject?.findIndex(data => data?.name === updateFabric?.name && data?.type === 'upholstery' && JSON.stringify(data?.fabric) === JSON.stringify(updateFabric)
          && JSON.stringify(data?.upholsteryItem) === JSON.stringify(upholsteryItem)
          && JSON.stringify(data?.paintName) === JSON.stringify(paintName)
        )
      } else {
        checkData = -1
      }

      if (checkData === -1) {
        setAddToProjectImage([base64Image, ...addToProjectImage])
        setAddToProject([addData, ...addToProject])
      } else {
        const updatedData = addToProject?.map((data, index) => {
          if (index === checkData) {
            return { ...data, quantity: data.quantity + 1 }
          } else {
            return data
          }
        })
        setAddToProject(updatedData)
      }
      document.querySelector("#model-viewer").cameraOrbit = '0deg 75deg 105%'
    }, 1000)
  }

  document.querySelector("#model-viewer")?.addEventListener("progress", (event) => {
    if (event.detail.totalProgress === 1) {
      setModelAtomValue({
        ...modelAtomValue,
        loading: 100,
      })
    }
  })
  if (modelAtomValue.src.replace('app', preFix) && preFix) {
    return (
      <>
        <div id="card" style={{
          opacity: modelAtomValue.loading === 100 ? '1' : '0',
          transition: 'opacity 1s ease-in-out'
        }}>
          <model-viewer
            tone-mapping="commerce"
            src={modelAtomValue.src.replace('app', preFix)}
            camera-controls
            disable-pan
            // camera-orbit="0deg 90deg 3m"
            style={{ height: "57vh", width: "100%" }}
            id="model-viewer"
          ></model-viewer>
        </div>
        {showButton && <button
          className="btn btn-outline-primary mb-3"
          style={{
            maxWidth: 50,
            width: '100%',
            margin: '0 auto'
          }}
          onClick={() => addToProjectHandler()}
        // disabled={loader ? true : false}
        >
          Add To Project
        </button>
        }
      </>
    );
  } else {
    return <></>
  }
};

export default UpholsteryModelViewer;
