// local credentials 
// export const material = {
//     pillowFabric: ["5f98f88a164cb394fc0b44ec"],
//     vintagePaint: ["5e9ee80be92bde00086ad274"],
//     legWood: ['5d7ce0484e8d3c763cf7b4de']
// }

// export const subMaterial = {
//     pillowFabric: ["5f98f88a164cb394fc0b44f7"],
//     pillowNo: ['63e39c8a170ca20008c169af'],
//     pillow2_4Side: ["642bfa6f93c41a00081721fa"],
//     waxVintagePaint: ['63f495f8e2a41600080efbea'],
//     glazeVintagePaint: ['63f4961d6185cd0008fe9577'],
//     upholsteryFabric: ['63e39c6f170ca20008c169a2'],
//     upholsteryFabric: ['63e39c6f170ca20008c169a2']
// }

// export const fabricCompanyId = '5f991484a0d40e0e988e1722'
// export const ImageGlobalUrl = 'https://in.pothigara.com/public'
// export const GQL_URL = 'http://localhost:4000/atlas9'
// export const GQL_URL = 'https://test.virtuosopastiche.com/test/atlas9'

export const TOKEN = 'Suitepeices@2024'
// prod credentials

export const material = {
    pillowFabric: ["5d7ce0484e8d3c763cf7b4df"],
    vintagePaint: ["5e9ee80be92bde00086ad274"],
    legWood: ['5d7ce0484e8d3c763cf7b4de']
}

export const ImageGlobalUrl = 'https://app.poly9.ai/public'
export const subMaterial = {
    pillowFabric: ["63e39c60170ca20008c16995"],
    pillowNo: ['63e39c8a170ca20008c169af'],
    pillow2_4Side: ["642bfa6f93c41a00081721fa"],
    waxVintagePaint: ['63f495f8e2a41600080efbea'],
    glazeVintagePaint: ['63f4961d6185cd0008fe9577'],
    // 63f4961d6185cd0008fe9577 - vintage
    // 63f495f8e2a41600080efbea - wax
    upholsteryFabric: ['63e39c6f170ca20008c169a2'],
    legWood: ['645a32de50436e000898a3b6']
}

export const fabricCompanyId = '636e083bd5c1580008c1d3fa'

export const GQL_URL = 'https://apis.polyfoundry.com/prod/atlas9'
export const Handle_Global_Url = 'https://s3.ap-south-1.amazonaws.com/app.poly9.ai/public/636e083bd5c1580008c1d3fa/website-image/handle_thumb/'
export const addVariant = 'https://shopify-add-to-cart.onrender.com/add-variant/'
export const removeVariant = 'https://shopify-add-to-cart.onrender.com/remove-variants/'
export const addVariantPillowId = '8913837359389'
export const addVariantVintageId = '8921953370397'
export const shopifyDomain = "suitepieces-cart-dev.myshopify.com"
export const shopifyToken = '5440239871464692a94611cf5388fffc'

export const addVariantProd = 'https://suitepieces-prod.onrender.com/add-variant/'
export const removeVariantProd = 'https://suitepieces-prod.onrender.com/remove-variants/'
export const addVariantPillowProdId = '8257349615868'
export const addVariantVintageProdId = '8218561282300'
export const shopifyDomainProd = "suitepieces.myshopify.com"
export const shopifyTokenProd = '861581ef625a239aa0f6230eae05a5ad'
// export const shopifyTokenProd = '7b4c6465a440dd2ba041b99a7c5dd67c' //'b34472f1a20c343feb33e65e5957bbc2'

export const fabricType = 'GET_FINISHES'
export const fabricKey = 'uMkvMKema7XmKXHhT'

export const upholsterySidebar = [
    {
        key: 'TAB_1',
        img: 'Fabric.svg',
        name: 'Fabric',
        parts: ["Base", "Seat", "Backrest", "Fabric", 'Arm_Style', 'Seat_Cushion', 'Back_Pillow', 'Sofa']
        // parts: ["Base", "Seat", "Backrest", "Fabric", 'Arm_Style', 'Seat_Cushion', 'Back_Pillow']
    },
    {
        key: 'TAB_2',
        img: 'ARM STYLE.svg',
        name: 'ARM STYLE',
        parts: ["Base", "Seat", "Backrest", "Fabric", 'Arm_Style', 'Seat_Cushion', 'Back_Pillow', 'Sofa']
    },
    {
        key: 'TAB_3',
        img: 'SEAT.svg',
        name: 'SEAT CUSHION',
        parts: ["Base", "Seat", "Backrest", "Fabric", 'Arm_Style', 'Seat_Cushion', 'Back_Pillow', 'Sofa']
    },
    {
        key: 'TAB_4',
        img: 'Back.svg',
        name: 'BACK PILLOW',
        parts: ["Base", "Seat", "Backrest", "Fabric", 'Arm_Style', 'Seat_Cushion', 'Back_Pillow', 'Sofa']
    },
    {
        key: 'TAB_5',
        img: 'Upholstery/leg.svg',
        name: 'BASE OR LEG',
        parts: ['Legs']
    },
    {
        key: 'TAB_6',
        img: 'NAILHEADS.svg',
        name: 'NAILHEADS',
        parts: ["Base", "Seat", "Backrest", "Fabric", 'Arm_Style', 'Seat_Cushion', 'Back_Pillow', 'Sofa']
    },
]

export const armStyleData = [
    {
        name: 'Track',
        img: 'Upholstery/Arm Style/track.png'
    },
    {
        name: 'Curved Flare',
        img: 'Upholstery/Arm Style/curve flare.png'
    },
    {
        name: 'Wide Track',
        img: 'Upholstery/Arm Style/wide track.png'
    },
]

export const seatCushionData = [
    {
        name: 'C1 T-cush/21" dp/3',
        img: 'Upholstery/Seat cushion/C1 T-cush-21.png'
    },
    {
        name: 'C2 T-cush/21" dp/2',
        img: 'Upholstery/Seat cushion/C2 T-cush-21.png'
    },
    {
        name: 'C3 T-cush/21" dp/1',
        // img: 'C2 T-cush-21.png'
        img: 'Upholstery/Seat cushion/C-1.png'
    },
    // {
    //     name: 'C4 T-cush/21" dp/3',
    //     img: 'C1 T-cush-21.png'
    // },
    // {
    //     name: 'C5 T-cush/21" dp/3',
    //     img: 'C2 T-cush-21.png'
    // },
    // {
    //     name: 'C6 T-cush/21" dp/3',
    //     img: 'C2 T-cush-21.png'
    // },
]

export const backPillowData = [
    {
        name: 'D16 BoxBorder /36h/3',
        img: 'Upholstery/Back Pillow/D16 BoxBorder-.png'
    },
    {
        name: 'D19 SingleWelt /36h/3',
        img: 'Upholstery/Back Pillow/D19 SingleWelt-.png'
    },
    {
        name: 'D20 SingleWelt /36h/3',
        img: 'Upholstery/Back Pillow/D20 SingleWelt-.png'
    },
    {
        name: 'D17 BoxBorder /36h/3',
        img: 'Upholstery/Back Pillow/D17 BoxBorder-.png'
    },
]

export const baseOrLegData = [
    {
        name: 'E21 155L leg',
        img: 'Upholstery/Leg/E21 155L leg.png'
    },
    {
        name: 'E21 4160L Leg',
        img: 'Upholstery/Leg/E21 4160L Leg.png'
    },
    {
        name: 'E21 4394L Leg',
        img: 'Upholstery/Leg/E21 4394L Leg.png'
    },
]
export const nailheadData = [
    {
        name: 'Yes',
        img: 'Group 1710.png'
    },
    {
        name: 'No',
        img: 'pillows-no.png'
    },
]
export const pillowAllSize = [
    {
        setSize: '12x20',
        image: '12x20.png',
        name: '12x20'
    },
    {
        setSize: '12x24',
        image: '12x24.png',
        name: '12x20'
    },
    {
        setSize: '14x20',
        image: '14x20.png',
        name: '12x20'
    },
    {
        setSize: '14x24',
        image: '14x24.png',
        name: '12x20'
    },
    {
        setSize: '18x18',
        image: '18x18.png',
        name: '20x20'
    },
    {
        setSize: '20x20',
        image: '20.png',
        name: '20x20'
    },
    {
        setSize: '22x22',
        image: '22x22.png',
        name: '20x20'
    },
    {
        setSize: '24x24',
        image: '24x24.png',
        name: '20x20'
    },

]
export const insertType = [
    {
        name: 'Pillow Cover Only',
        image: 'Pillow Cover Only.png'
    },
    {
        name: 'Down',
        image: 'Down.png'
    },
    {
        name: 'Faux Down',
        image: 'Faux Down.png'
    }
]
export const yardValue = {
    '12x20': {
        'yard': 0.5,
        'Vertical': 1,
        'Inset Band': 2,
        'Center': 0.5,
        'Flange 1"': 0.5,
        'Flange 2"': 0.5,
        '4 Side': 2,
        '2 Side': 1,
        'Welt': 0.5,
    },
    '20x20': {
        'yard': 0.75,
        'Vertical': 1.5,
        'Inset Band': 2,
        'Center': 0.75,
        'Flange 1"': 0.5,
        'Flange 2"': 0.5,
        '4 Side': 2.5,
        '2 Side': 1.5,
        'Welt': 0.5,
    },
}

export const edgeSellPriceCalulate = {
    '4 Side': 20,
    '2 Side': 20,
    'Welt': 0,
    'Flange 1"': 30,
    'Flange 2"': 30,
}

export const pricingValueCalculate = {
    "4 Side": "trimFourSides",
    "2 Side": "trimTwoSides",
    "Welt": "welt",
    'Flange 1"': "oneFlange",
    'Flange 2"': "twoFlange",
    "Center": "centerBands",
    "Inset Band": "insetBands",
    "Vertical": "verticalBands",

}
export const edgeCostPriceCalulate = {
    '4 Side': 10,
    '2 Side': 10,
    'Welt': 0,
    'Flange 1"': 15,
    'Flange 2"': 15,
}

export const bandSellPriceCalulate = {
    'Center': 20,
    'Inset Band': 50,
    'Vertical': 20,
}

export const bandCostPriceCalulate = {
    'Center': 10,
    'Inset Band': 25,
    'Vertical': 10,
}

export const orderOption = [
    { label: 'Pending', value: 'pending' },
    { label: 'Paid Order', value: 'paidOrder' },
    { label: 'Ordered w/Vendor', value: 'vendorOrder' },
    { label: 'Shipped', value: 'shipped' },
    { label: 'Received', value: 'received' },
    { label: 'Sent to Workroom', value: 'sentToWorkroom' },
    { label: 'Ready for Pickup', value: 'readyForPickup' },
    { label: 'Closed', value: 'closed' },
]

export const paymentOption = [
    { label: 'Pending', value: 'pending' },
    { label: 'Paid', value: 'paid' },
]