import React, { useEffect } from 'react';
import Customize from './customization';
import content, { PillowModelData } from "../../../content";
import { useRecoilState, useRecoilValue } from 'recoil';
import { vintageAtom, fabricAtom, modelAtom, upholsteryAtom, drapryAtom, currentCustomizationAtom, tabAtom, selectedFabricAtom, currentPillowStateAtom, uploadImageAtom, selectedAlphaAtom, loaderAtom } from '../../../state';

interface IInspirationCategory { name: string, image: string, modelName?: string, settings?: { size: string, fabricFront: string, fabricBack: string, border: string, band: string } }

const Inspirations = ({ category }: { category: string }) => {
    const [modelSrc, setModelSrc] = useRecoilState<{ categoryName: string, src: string, loading: number }>(modelAtom);

    const [selectedProduct, setSelectedProduct] = React.useState<number>(0);
    const [fabricAtomState, setFabricsAtomState] = useRecoilState<{ partWiseFabric: any, currentPart: any }>(fabricAtom);
    const [drapryAtomState, setdrapryAtomState] = useRecoilState(drapryAtom);
    const [upholsteryAtomState, setUpholsteryAtomState] = useRecoilState<{ partWiseFabric: any, currentPart: string[], name: string }>(upholsteryAtom);
    const [currentVintage, setCurrentVintage] = React.useState<string>("");
    const [vintageAtomState, setVintageAtomState] = useRecoilState<{ handle: string; code: string; paint: string; image: string; sortName: string; finish: string; name: string; partWiseFabric: any, currentPart: string[] }>(vintageAtom);
    const [currentCustomization, setCurrentCustomization] = useRecoilState<any>(currentCustomizationAtom);
    const [tab, setTab] = useRecoilState<string>(tabAtom);
    const [selectedFabric, setSelectedFabric] = useRecoilState<any>(selectedFabricAtom);
    const [currentPillowState, setCurrentPillowState] = useRecoilState<any>(currentPillowStateAtom);
    const [uploadImage, setUploadImage] = useRecoilState<any>(uploadImageAtom)
    const [alphaSelect, setAlphaSelect] = useRecoilState(selectedAlphaAtom)
    const loader = useRecoilValue(loaderAtom)

    useEffect(() => {
        setSelectedProduct(0);
        switch (category) {
            case 'pillow':
                setModelSrc({
                    ...modelSrc,
                    categoryName: category,
                    src: content.inspirations.PILLOW[0].modelName
                });
                break;
            case 'vintage':
                const VINTAGE_FURNITURE: any = content.THREE_D_MODELS["VINTAGE_FURNITURE"];
                const key = Object.keys(VINTAGE_FURNITURE)[0];
                setVintageAtomState({
                    ...vintageAtomState,
                    name: key,
                    handle: "Black Walnut Wood And Gold Ends TKnob",
                    sortName: 'Wood T-Knob',
                    paint: '',
                    code: "31739265056827",
                    partWiseFabric: {}
                })

                const src = VINTAGE_FURNITURE[key]["Black_Walnut_Wood_And_Gold_Ends_TKnob"];
                setCurrentVintage(key);
                setModelSrc({ ...modelSrc, categoryName: category, src: src.id });
                break;
            case 'drapery':
                setModelSrc({
                    ...modelSrc,
                    categoryName: category,
                    src: content.inspirations.DRAPERY["Drapery"].modelName
                });
                setVintageAtomState({ ...vintageAtomState, name: "Drapery", paint: '', partWiseFabric: {} })
                break;
            case 'upholstery':
                setModelSrc({
                    ...modelSrc,
                    categoryName: category,
                    src: content.inspirations.UPHOLSTERY[0].modelName
                });
                break;
            default:
                break;
        }
    }, [category]);

    const renderSwitch = () => {
        const { inspirations } = content;
        switch (category) {
            case 'pillow':
                return <div className="d-flex align-items-center justify-content-center product-row">
                    {Object.values((inspirations["PILLOW"])).map((pillow: IInspirationCategory | any, index: number) => (
                        <div className='text-center d-flex flex-column align-items-center' key={index}>
                            <div className={`product-info-box mx-3 
                            ${index === selectedProduct ? 'active-category' : ''} `}
                                key={index}
                                onClick={() => {
                                    if (loader) {
                                        // if (modelSrc.loading !== 100) {
                                    } else {
                                        setCurrentPillowState(pillow.name)
                                        setSelectedFabric({})
                                        setTab('TAB_1')
                                        setUploadImage({})
                                        setFabricsAtomState({
                                            currentPart: [],
                                            partWiseFabric: {},
                                        })
                                        setModelSrc({
                                            ...modelSrc,
                                            categoryName: category,
                                            src: pillow.modelName
                                        });
                                        setSelectedProduct(index);
                                        setCurrentCustomization({
                                            color: "",
                                            pattern: "",
                                            size: {
                                                name: "12x20",
                                                image: "12x20.png",
                                                setSize: '12x20'
                                            },
                                            quantity: 1,
                                            border: {
                                                name: 'No',
                                                image: "pillows-no.png",
                                            },
                                            band: {
                                                name: 'No',
                                                image: "pillows-no.png",
                                                type: 'No'
                                            },
                                            insertType: {
                                                name: 'Pillow Cover Only',
                                                image: 'Pillow Cover Only.png'
                                            }
                                        })
                                    }
                                }}
                            >
                                <img
                                    src={pillow.image}
                                    className="img-fluid"
                                    alt={pillow.name}
                                />
                            </div>
                            <p className={`w-75 break-word mb-0 m-font ${index === selectedProduct ? '' : 'color-gray'}`} style={{ height: '40px' }}>{pillow.name}</p>
                        </div>))}
                </div>
            case 'vintage':
                return <>
                    <div className="d-flex align-items-center justify-content-center product-row">
                        {Object.values((inspirations["VINTAGE"])).map((vintage: IInspirationCategory, index: number) => {
                            return (
                                <div className='text-center d-flex flex-column align-items-center' key={vintage.name}>
                                    <div
                                        className={`product-info-box mx-3 ${vintageAtomState?.name === vintage.name.split(" ").join("_") ? 'active-category' : ''} `}
                                        onClick={() => {
                                            if (loader) {
                                                // if (modelSrc.loading !== 100) {
                                            } else {
                                                setUploadImage({})
                                                // setSelectedFabric({})
                                                setVintageAtomState({
                                                    ...vintageAtomState, name: vintage.name.split(" ").join("_"),
                                                    //  paint: '', partWiseFabric: {}
                                                })
                                                const THREE_D_MODELS = content.THREE_D_MODELS
                                                const VINTAGE_FURNITURE: any = THREE_D_MODELS["VINTAGE_FURNITURE"];
                                                const src = VINTAGE_FURNITURE[vintage.name.split(" ").join("_")][vintageAtomState.handle.split(" ").join("_")].id;
                                                setModelSrc({
                                                    ...modelSrc,
                                                    categoryName: category,
                                                    src: src
                                                });
                                                setAlphaSelect({
                                                    name: 'Clear Wax',
                                                    type: 'wax',
                                                    image: 'https://customer.polynine.com/poc/suitepieces/fabric/vintage/Clear%20Wax.png',
                                                    index: 1,
                                                    exposure: 0.7,
                                                    code: "42049056866556"
                                                })
                                            }
                                        }} key={index}>
                                        <img
                                            src={vintage.image}
                                            className="img-fluid"
                                            alt={vintage.name}
                                        />
                                    </div>
                                    <p className={`w-75 break-word mb-0 m-font ${vintageAtomState?.name === vintage.name.split(" ").join("_") ? '' : 'color-gray'}`}>{vintage.name}</p>
                                </div>
                            )
                        })}
                    </div>
                </>
            case 'drapery':
                return <>
                    <div className="d-flex align-items-center justify-content-center product-row">
                        {Object.values((inspirations["DRAPERY"])).map((drapery: IInspirationCategory, index: number) => {
                            return (
                                <div className='text-center d-flex flex-column align-items-center' key={drapery.name}>
                                    <div className={`product-info-box mx-3 
                                    ${drapryAtomState.name === drapery.name ? 'active-category' : ''} 
                                    `}
                                        onClick={() => {
                                            if (loader) {
                                                // if (modelSrc.loading !== 100) {
                                            } else {
                                                setModelSrc({
                                                    ...modelSrc,
                                                    categoryName: category,
                                                    src: drapery.modelName || "",
                                                });
                                                setdrapryAtomState({
                                                    ...drapryAtomState,
                                                    name: drapery.name || "",
                                                });
                                            }
                                        }}
                                        key={index}>
                                        <img
                                            src={drapery.image}
                                            className="img-fluid"
                                            alt={drapery.name}
                                        />
                                    </div>
                                    <p className={`w-90 break-word m-font ${drapryAtomState.name === drapery.name ? '' : 'color-gray'}`}>{drapery.name}</p>
                                </div>
                            )
                        })}
                    </div>
                </>
            case 'upholstery':
                return <>
                    <div className="d-flex align-items-center justify-content-center product-row">
                        {Object.values((inspirations["UPHOLSTERY"])).map((upholstery: IInspirationCategory, index: number) => (
                            <div className='text-center d-flex flex-column align-items-center' key={upholstery.modelName}>
                                <div className={`product-info-box mx-3 ${index === selectedProduct ? 'active-category' : ''} `}
                                    onClick={() => {
                                        if (loader) {
                                            // if (modelSrc.loading !== 100) {
                                        } else {
                                            // const DRAPERY: any = THREE_D_MODELS["PILLOW"];
                                            const modalSrc = upholstery.modelName || "";
                                            setModelSrc({
                                                ...modelSrc,
                                                categoryName: category,
                                                src: modalSrc
                                            });
                                            setUpholsteryAtomState({
                                                ...upholsteryAtomState,
                                                name: upholstery.name
                                            })
                                            setSelectedProduct(index);
                                        }
                                    }}
                                    key={index}>
                                    <img
                                        src={upholstery.image}
                                        className="img-fluid"
                                        alt={upholstery.name}
                                    />
                                </div>
                                <p className={`w-90 break-word m-font ${index === selectedProduct ? '' : 'color-gray'}`}>{upholstery.name}</p>
                            </div>
                        ))}
                    </div>
                </>
            default:
                return <>
                    <div className="container">
                        INVALID CATEGORY!!
                    </div>
                </>
        }
    }
    return (
        <>
            <div>
                <div>
                    {renderSwitch()}
                    <Customize category={category} setSelectedProduct={setSelectedProduct} selectedProduct={selectedProduct} />
                </div>
            </div>
        </>
    );
};

export default Inspirations;