import { gql } from '@apollo/client';

export const SIGN_UP = gql`
mutation signUp($input:userInput){
  signUp(input:$input){
    _id
    name
    email
    type
  }
}

`
export const SIGN_IN = gql`
mutation customerLogin($email:String , $password:String){
  customerLogin(email:$email , password:$password){
    token
    user{
      _id
      name
      isEmailVerify
      isAdmin
      email
    }
  }
}

`
export const VERIFY_EMAIL = gql`
mutation verifyEmail($code:String ){
  verifyEmail(code:$code ){
    token
    user{
      _id
      name
      isEmailVerify
      isAdmin
      email
    }
  }
}
`
export const RESEND_EMAIL = gql`
mutation ResendEmailVerificationLink($input: resendEmailInput!) {
  resendStoreFrontEmailVerificationLink(input: $input)
}
`
export const FORGOT_PASSWORD = gql`
mutation forgotPassword($email:String , $domain:String ){
  forgotPassword(email:$email, domain:$domain )
}
`
export const RESET_PASSWORD = gql`
mutation verifyForgotPassword($code:String , $password:String ){
  verifyForgotPassword(code:$code, password:$password )
}
`