import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserIcon } from "../../icons/user-icon";
import { toast } from "react-toastify";
import { useModalAction } from "../modal/modal.context";
import { Menu, Transition } from "@headlessui/react";


const Header = ({ sharePageMenus }: any) => {

    const history = useNavigate();

    const { openModal } = useModalAction();

    const accessToken = localStorage.getItem('access-token');
    const user = localStorage.getItem('userData');
    const userData = user && JSON.parse(user);

    const onLogout = () => {
        localStorage.removeItem('access-token');
        localStorage.removeItem('userData');
        toast.success('Logged out successfully')
        openModal('LOGIN')
    }


    return (
        <div className="grid grid-cols-12 sm:py-0 py-2">
            <div className="sm:col-span-7 col-span-5 flex sm:justify-end justify-start">
                <img
                    onClick={() => {
                        if (sharePageMenus && !accessToken) return
                        history('/')
                    }}
                    src="https://cdn.shopify.com/s/files/1/0094/2584/6331/files/logo_2_fab14dcd-68e2-47e1-806b-64a5b79c740d_400x.png?v=1671636282"
                    alt=""
                    className="sm:h-[80px] h-[50px] cursor-pointer"
                />
            </div>

            {!sharePageMenus ? <div className="sm:col-span-5 col-span-7 flex justify-end items-center space-x-4 rtl:space-x-reverse pr-5">
                {!sharePageMenus ? <div className="sm:col-span-2 col-span-2 flex sm:justify-end justify-start" style={{ alignItems: 'center', marginLeft: '20px' }}>
                    <Link to='/' className=" py-2 px-3 font-medium sm:text-[13px] text-[11px] text-black no-underline">Home</Link>
                </div> : <div className="sm:col-span-2 col-span-2 flex sm:justify-start justify-start" style={{ alignItems: 'center', marginLeft: '20px' }}></div>}

                <Link to='/orders' className=" py-2 px-2 font-medium sm:text-[13px] text-[11px] text-black no-underline me-2 ms-0">My Orders</Link>

                <Menu
                    as="div"
                    className="relative inline-block text-left rtl:text-right ms-1"
                >
                    <Menu.Button className="flex items-center focus:outline-0 ">
                        {userData && <p className="font-medium sm:text-[13px] text-[11px] text-black flex items-center m-0">
                            <div className="bg-gray-200 py-2 px-3 rounded-full sm:p-2 p-1 ml-1.5 d-flex align-items-center">
                                <UserIcon />
                                <p className="mb-0 ml-1">
                                    {userData?.name}
                                </p>
                            </div>
                        </p>}
                    </Menu.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            as="ul"
                            className='ml-0 absolute mt-1 w-48 rounded bg-white shadow-700 focus:outline-none right-0 origin-top-right rtl:left-0 rtl:origin-top-left py-2'
                        >
                            <Menu.Item key="">
                                <li>
                                    <button
                                        className='block w-full px-6 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent focus:outline-0 text-left rtl:text-right'
                                    >
                                    </button>
                                </li>
                            </Menu.Item>
                            <Menu.Item>
                                {userData ?
                                    <li>
                                        <button
                                            onClick={onLogout}
                                            className='block w-full py-2.5 px-6 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent focus:outline-0 text-left rtl:text-right'
                                        >Logout</button>
                                    </li>
                                    :
                                    <li>
                                        <button
                                            onClick={() => openModal('LOGIN')}
                                            className='block w-full py-2.5 px-6 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent focus:outline-0 text-left rtl:text-right'
                                        >Login</button>
                                    </li>
                                }
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>

            </div> : <div className="sm:col-span-5 col-span-7 flex justify-end items-center space-x-4 rtl:space-x-reverse pr-5"></div>}
        </div>
    )
}

export default Header;