import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useRecoilState } from 'recoil';
import { addFabricBeforeAtom, canvasToggleRenderAtom, fabricAtom, selectedCustomFabricAtom, selectedFabricAtom, showModalAtom, uploadFabricAtom, uploadImageAtom } from '../../state';

const MyDropzone = () => {
    const [showModal, setShowModal] = useRecoilState(showModalAtom)
    const [fabric, setFabricsAtomState] = useRecoilState(fabricAtom);
    const [selectedFabric, setSelectedFabric] = useRecoilState(selectedFabricAtom);
    const [uploadFabricModel, setUploadFabricModel] = useRecoilState(uploadFabricAtom);
    const [addFabricBefore, setAddFabricBefore] = useRecoilState(addFabricBeforeAtom);
    const [selectedCustomFabric, setSelectedCustomFabricAtom] = useRecoilState(selectedCustomFabricAtom)
    const [canvasToggleRender, setCanvasToggleRender] = useRecoilState(canvasToggleRenderAtom)
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles?.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = async () => {
                const binaryStr = reader.result
                const fabricName = fabric?.currentPart?.[0]
                setAddFabricBefore({ ...addFabricBefore, [fabricName]: binaryStr })
                if (fabric?.partWiseFabric.Front || fabric?.partWiseFabric.Back) {
                    // Create a copy of the existing partWiseFabric object
                    const partWiseFabricCopy = { ...fabric?.partWiseFabric };
                    // Remove the fabricName key from the partWiseFabricCopy object
                    delete partWiseFabricCopy[fabricName];
                    // Update the state with the modified partWiseFabric object
                    setFabricsAtomState({ ...fabric, partWiseFabric: partWiseFabricCopy });
                }
                setSelectedCustomFabricAtom({ id: '' })
                setSelectedFabric({})
                setShowModal(true)
                setUploadFabricModel(false)
                setCanvasToggleRender([])
            }
            reader.readAsDataURL(file);
        })
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { 'image/*': [] } })

    return (
        <div {...getRootProps()}>
            <input id="image" type="file" className="dropzone" {...getInputProps()} />
            <div className="text-center cursor-pointer drop-zone d-flex flex-column justify-content-center">
                <div className="item-choose mx-2 mb-3">
                    <img src="Group 1786.png" id="image" className="img-fluid m-auto" alt='camo' />
                </div>
                <div className='color-gray'>
                    Drop your image here or
                    <span className='green-color'> browse</span>
                </div>
            </div>
        </div>
    )
}
export default MyDropzone;