import Modal from './modal';
import { useModalAction, useModalState } from './modal.context';
import Register from '../Auth/Register';
import Login from '../Auth/Login';
import ForgotPassword from '../Auth/ForgotPassword';
import CreateNewPassword from '../Auth/CreateNewPassword';
import EmailConfirmation from '../Auth/EmailConfirmation';
import ResendEmailConfirmation from '../Auth/ResendEmailConfirmation';
import OrderStatus from '../Auth/OrderStatus';

const ManagedModal = () => {
  const { isOpen, view } = useModalState();
  const { closeModal } = useModalAction();

  return (
    <Modal open={isOpen} onClose={closeModal}>
      {view === 'REGISTER' && <Register />}
      {view === 'LOGIN' && <Login />}
      {view === 'FORGOT_PASSWORD' && <ForgotPassword />}
      {view === 'CREATE_PASSWORD' && <CreateNewPassword />}
      {view === 'EMAIL_CONFIRMATION' && <EmailConfirmation />}
      {view === 'RESEND_EMAIL_VERIFICATION' && <ResendEmailConfirmation />}
      {view === 'ORDER_STATUS' && <OrderStatus />}

    </Modal>
  );
};

export default ManagedModal;
