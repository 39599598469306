import { useQuery } from "@apollo/client";
import { GET_PRODUCT_SUMMARY_DATA } from "../../../apollo/gql/query/productSummary.query";
import Header from "../../../components/Header";
import MyOrders from '../../../components/orders/my-orders';
import OrderDetails from '../../../components/orders/order-details';
import { useEffect, useState } from 'react';
import { Spinner } from "reactstrap";
import { isUserAdmin } from "../../../utils/utils";


const Orders = () => {

  const userDataJSON = localStorage.getItem('userData');
  const userData = userDataJSON ? JSON.parse(userDataJSON) : null;

  const [productSummary, setProductSummary] = useState<any>([])
  const [order, setOrder] = useState<any>()

  const { data: summaryData, loading: summaryLoading, refetch: refetchPricingData } = useQuery(GET_PRODUCT_SUMMARY_DATA, {
    variables: {
      isAdmin: isUserAdmin(),
      customerId: !isUserAdmin() ? userData?._id : ""
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (summaryData && summaryData.getProductSummary) {
      setProductSummary(summaryData.getProductSummary)
      if (!order) {
        setOrder(summaryData.getProductSummary[0])
      } else {
        const newData = summaryData?.getProductSummary?.find((data: any) => data?._id === order?._id)
        if (newData) {
          setOrder(newData)
        }
      }
    }
  }, [summaryData])

  return (
    <>
      <Header />
      {!productSummary.length && !summaryLoading ?
        <div
          style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >No order found.</div>
        : <div className='bg-gray-100'>
          <div className='p-10'>
            {!productSummary.length && summaryLoading ? <div
              style={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <Spinner size="bg" style={{ width: "2.5rem", height: "2.5rem" }} className='mt-3'> </Spinner>
            </div> :
              <div className='grid grid-cols-12 gap-4'>

                <div className='col-span-12 lg:col-span-3'><MyOrders productSummary={productSummary} setOrder={setOrder} /></div>
                <div className='col-span-12 lg:col-span-9'><OrderDetails order={order} refetch={refetchPricingData} /></div>
              </div>
            }
          </div>
        </div>}
    </>
  )
}
export default Orders;