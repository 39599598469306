import { useRecoilState, useRecoilValue } from 'recoil'
import { itemsListAtom, preFixAtom, productListAtom } from '../../state';
import { useEffect, useState } from 'react';
import OrderStatus from '../../pages/Home/orders/OrderStatus';
import { isUserAdmin, ScrollTable } from '../../utils/utils';
import { CLOUD_FRONT_URL, US_CLOUD_FRONT_URL } from '../../aws.config';
import ListStatus from '../../pages/Home/orders/ListStatus';
import { orderOption, paymentOption } from '../../lib/constant';
import { DELETE_PROJECT_SUMMARY } from '../../pages/Home/graphQl/Mutation';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Notification } from '../../lib/Notification';
import Modal from 'react-bootstrap/Modal';
import { Spin } from 'antd';

export const OrderItems = ({
  products,
  order,
  refetch
}: {
  products: any;
  order: any;
  refetch: any;
}) => {

  const [isOrderStatusOpen, setIsOrderStatusOpen] = useState(false);
  const [isListStatusOpen, setIsListStatusOpen] = useState(false);
  const [productList, setProductList] = useRecoilState<any>(productListAtom)
  const [itemsList, setItemsList] = useRecoilState<any>(itemsListAtom)
  const [productName, setProductName] = useState<any>('')
  const [selectedPart, setSelectedPart] = useState<any>({})
  const [productSKU, setProductSKU] = useState<any>('')
  const [show, setShow] = useState(false);
  const preFix = useRecoilValue(preFixAtom);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState()
  const nameChange: any = {
    "Front": 'Front',
    "Back": 'Back',
    "border": "Border",
    "band": "Band"
  }
  const [deleteProjectMutation] = useMutation(DELETE_PROJECT_SUMMARY)

  useEffect(() => {
    if (products && products.length) {
      let filteredProducts = products?.filter((x: any) => x?.type !== 'vintage')
      setProductList(filteredProducts)
      setItemsList(filteredProducts?.map((d: any) => ({ ...d, name: d?.name || "", vendor: d?.vendor || "", type: d?.type || "", po: d?.po || "", shipCost: d?.shipCost || "" })))
    }
  }, [products])


  const toggleOrderStatus = (productName: string, productSKU: any) => {
    setIsOrderStatusOpen(!isOrderStatusOpen);
    setProductName(productName)
    setProductSKU(productSKU)
  }

  const toggleListStatus = (productName: string, productSKU: any) => {
    setIsListStatusOpen(!isListStatusOpen);
    setProductName(productName)
    setProductSKU(productSKU)
  }

  const deleteOrder = () => {
    const input = {
      _id: order?._id,
      productIndex: selectedIndex
    }
    deleteProjectMutation({ variables: { input: input } })
      .then(({ data }) => {
        if (data) {
          toast.success(Notification['Product order'])
          refetch()
          setShow(false)
          setLoading(false)
        }
      }).catch(err => {
        toast.error(err?.message || err)
        setLoading(false)
      })
  }

  return (
    <>
      <table className='w-full' style={{ minWidth: isUserAdmin() ? 1500 : 500 }}>
        <Modal className="delete-product-model" show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton className='m-0 delete-header-border'>
            <Modal.Title className="m-auto">Delete Product</Modal.Title>
          </Modal.Header>
          <Modal.Body className='mb-0' style={{ width: 'unset' }}>
            <p className='m-0 pt-3'>Are you sure you want to delete Product?</p>
          </Modal.Body>
          <Modal.Footer className='justify-content-center'>
            <button
              className="btn btn-outline-primary  mt-3 ms-3"
              onClick={() => {
                if (!loading) {
                  setShow(false)
                }
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary  mt-3 ms-3"
              onClick={() => {
                if (!loading) {
                  setLoading(true)
                  deleteOrder()
                }
              }}
            >
              Ok {loading && <Spin className="submit-loader" />}
            </button>
          </Modal.Footer>
        </Modal>
        <thead><tr>
          <td className='fw-bold table-cell w-[30px]'>No</td>
          <td className='fw-bold table-cell '>Product</td>
          {isUserAdmin() && <td className='fw-bold table-cell w-[40px]'>Sku</td>}
          <td className='fw-bold table-cell w-[140px]'>Description</td>
          <td className='fw-bold table-cell w-[40px]'>Qty</td>
          {isUserAdmin() && <td className='fw-bold table-cell w-[70px]'>Vendor</td>}
          {isUserAdmin() && <td className='fw-bold table-cell w-[55px]'>Cost</td>}
          <td className='fw-bold table-cell w-[55px]'>Sell</td>
          {isUserAdmin() &&
            <>
              <td className='fw-bold table-cell w-[50px]'>Type</td>
              <td className='fw-bold table-cell w-[40px]'>PO#</td>
              <td className='fw-bold table-cell w-[40px]'>Reference#</td>
              <td className='fw-bold table-cell w-[60px]'>Shipping Cost#</td>
            </>
          }
          <td className='fw-bold table-cell w-[60px]'>Order Status#</td>
          {isUserAdmin() && <td className='fw-bold table-cell w-[60px]'>Payment Status#</td>}
          {isUserAdmin() && <td className='fw-bold table-cell w-[40px]'>Action</td>}
        </tr>
        </thead>
        <tbody>
          {productList?.map((pivot: any, index: any) => {

            const { name: pillowName, parts: summaryPart } = pivot;
            const findPart = (type: any) => summaryPart?.find((data: any) => data?.type === type);
            const pillowFront = findPart('Front');
            const pillowBack = findPart('Back');
            const pillowSize = findPart('size')?.name || '';
            const pillowEdge = findPart('edge') || findPart('border');
            const pillowBand = findPart('band');
            const insertType = findPart('insertType');
            return (
              <>
                <tr>
                  <td className='table-cell w-[30px]'><span className='text-black'>{`0${index + 1}`}</span></td>
                  <td className='table-cell ' style={{ maxWidth: 150, width: '10%' }}><div className='flex justify-between items-center'>
                    <img className='max-w-[50px]' src={`${preFix === "us" ? US_CLOUD_FRONT_URL : CLOUD_FRONT_URL}${pivot?.base64Image}`} alt="Card image cap" style={{ maxHeight: "100%" }} />
                    {pivot?.base64ImageBack && <img className='max-w-[50px]' src={`${preFix === "us" ? US_CLOUD_FRONT_URL : CLOUD_FRONT_URL}${pivot?.base64ImageBack}`} alt="Card image cap"
                      style={{ maxHeight: "100%" }} />}
                  </div></td>
                  {isUserAdmin() && <td className='table-cell w-[40px]'><span className='text-black'>{pivot?.seriesName}</ span></td>}
                  <td className='table-cell w-[140px]'><div>
                    <p className="m-0 text-black">Name: {pillowName}</p>
                    <p className="m-0 text-black">Size: {pillowSize}</p>
                    {isUserAdmin() && pillowFront?.name && <p className="m-0 text-black">Front: {pillowFront?.name}</p>}
                    {isUserAdmin() && pillowBack?.name && <p className="m-0 text-black">Back: {pillowBack?.name}</p>}
                    {isUserAdmin() && pillowEdge && <p className="m-0 text-black">{pillowEdge?.name}: {pillowEdge?.finishName}</p>}
                    {isUserAdmin() && pillowBand && <p className="m-0 text-black">{pillowBand?.name}: {pillowBand?.finishName}</p>}
                    {insertType && <p className="m-0 text-black">Insert: {insertType?.name}</p>}
                  </div></td>
                  <td className='table-cell w-[40px]'><span className='text-black'>{pivot?.quantity}</span></td>
                  {isUserAdmin() && <td className='table-cell w-[70px]'> <span className='text-black'></span></td>}
                  {isUserAdmin() && <td className='table-cell w-[55px]'> <span className='text-black'>{pivot?.totalCost?.toFixed(2)}</span></td>}
                  <td className='table-cell w-[55px]'> <span className='text-black'>{pivot?.sumCalculate?.toFixed(2)}</span></td>
                  {isUserAdmin() &&
                    <>
                      <td className='table-cell w-[50px]'> <span className='text-black'>Pillow</span></td>
                      <td className='table-cell w-[40px]'> <span className='text-black'></span></td>
                      <td className='table-cell w-[40px]'> <span className='text-black'></span></td>
                      <td className='table-cell w-[60px]'> <span className='text-black'></span></td>
                    </>
                  }
                  <td className='table-cell w-[60px]'> <button disabled={!isUserAdmin()} onClick={() => { toggleOrderStatus(pivot?.name, pivot?.seriesName) }} className='text-red-500 cursor-pointer text-start'>
                    {orderOption?.find(x => x?.value === pivot?.orderStatus)?.label || "Pending"}
                  </button></td>
                  {isUserAdmin() && <td className='table-cell w-[60px]'> <button disabled={!isUserAdmin()} onClick={() => { toggleOrderStatus(pivot?.name, pivot?.seriesName) }} className='text-yellow-500 cursor-pointer text-start'>
                    {paymentOption?.find(x => x?.value === pivot?.paymentStatus)?.label || "Pending"}</button></td>}
                  {isUserAdmin() && <td className='table-cell w-[40px]' >
                    <div>
                      <button className='text-black cursor-pointer' onClick={() => { toggleOrderStatus(pivot?.name, pivot?.seriesName) }}>Edit</button>
                    </div>
                    <div>
                      <button className='text-black cursor-pointer mt-3' onClick={() => {
                        setShow(true)
                        setSelectedIndex(index)
                      }}>Delete</button>
                    </div>
                  </td>}
                </tr>
                {isUserAdmin() &&
                  pivot?.parts?.filter((part: any) => part?.type !== "size" && part?.type !== "insertType").map((part: any) => {
                    if (part?.name) {
                      return (
                        <tr>
                          <td className='table-cell'></td>
                          <td className='table-cell'><img className='max-w-[50px] m-auto' src={part?.img} alt="Card image cap" style={{ maxHeight: "100%" }} /></td>
                          <td className='table-cell'><span className='text-black'>{part?.sku}</ span></td>
                          <td className='table-cell'> <p className="m-0 text-black">{nameChange[part?.type] || part?.type}: {part?.name}</p></td>
                          <td className='table-cell'> <span className='text-black'>{part?.quantity}</span></td>
                          <td className='table-cell'> <span className='text-black'>{part?.vendor}</span></td>
                          <td className='table-cell'> <span className='text-black'>{part?.costPrice?.toFixed(2)}</span></td>
                          <td className='table-cell'> <span className='text-black'>{part?.sellPrice?.toFixed(2)}</span></td>
                          <td className='table-cell w-[60px]'></td>
                          <td className='table-cell'> <span className='text-black'>{part?.po}</span></td>
                          <td className='table-cell w-[60px]'>{part?.refrenceID}</td>
                          <td className='table-cell w-[60px]'>{part?.shipCost}</td>
                          {/* <td className='table-cell w-[60px]'></td> */}
                          <td className='table-cell w-[60px]'>
                            <button disabled={!isUserAdmin()}
                              onClick={() => {
                                setSelectedPart(part)
                                toggleListStatus(pivot?.name, pivot?.seriesName)
                              }} className='text-red-500 cursor-pointer text-start'>
                              {orderOption?.find(x => x?.value === part?.orderStatus?.value)?.label || "Pending"}</button></td>
                          <td className='table-cell w-[40px]'></td>
                          {/* {isUserAdmin() && <td className='table-cell w-[40px]'></td>} */}
                          {isUserAdmin() && <td
                            onClick={() => {
                              setSelectedPart(part)
                              toggleListStatus(pivot?.name, pivot?.seriesName)
                            }}
                            className='table-cell w-[40px]'>
                            <button
                              className='text-black cursor-pointer '
                            >Edit
                            </button>
                          </td>}
                        </tr >
                      )
                    }
                  })
                }
              </>
            )

          })}

        </tbody>

      </table>

      {isOrderStatusOpen && <OrderStatus
        productSKU={productSKU}
        visible={isOrderStatusOpen}
        toggle={() => toggleOrderStatus("", "")}
        productName={productName}
        refetch={refetch}
        productList={productList}
        orderId={order?._id}
        orderNumber={order?.orderNumber}
      />
      }

      {isListStatusOpen && <ListStatus
        orderNumber={order?.orderNumber}
        productSKU={productSKU}
        visible={isListStatusOpen}
        toggle={() => toggleListStatus("", "")}
        productName={productName}
        refetch={refetch}
        productList={productList}
        orderId={order?._id}
        selectedPart={selectedPart}
      />
      }
    </>
  );
};
