import React, { useEffect, useState } from 'react'
import Header from '../../../../components/Header'

import { useNavigate } from 'react-router-dom';

//apollo
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_COMPANY_META_PRICING } from '../../../../apollo/gql/mutation/company-meta'
import { GET_COMPANY_META } from '../../../../apollo/gql/query/company-meta'

//formik
import { useFormik } from "formik";
import { object, string } from "yup";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fabricCompanyId } from '../../../../lib/constant';
import { Button } from 'reactstrap';
import { Spin } from 'antd';


const Pricing = () => {

  const [pricingMeta, setPricingMeta] = useState<any>()

  const [updatePricingMutation] = useMutation(UPDATE_COMPANY_META_PRICING)
  const { data: pricingData, refetch: refetchPricingData } = useQuery(GET_COMPANY_META, {
    variables: {
      companyId: fabricCompanyId
    }
  })
  const [loading, setLoading] = useState(false)

  const history = useNavigate();

  useEffect(() => {
    if (pricingData && pricingData.getCompanyMeta && pricingData.getCompanyMeta.pricing) {
      setPricingMeta(pricingData.getCompanyMeta.pricing)
    }
  }, [pricingData])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      widePillowBase: pricingMeta?.widePillowBase || 0,
      squarePillowBase: pricingMeta?.squarePillowBase || 0,
      verticalBands: pricingMeta?.verticalBands || 0,
      insetBands: pricingMeta?.insetBands || 0,
      centerBands: pricingMeta?.centerBands || 0,
      oneFlange: pricingMeta?.oneFlange || 0,
      twoFlange: pricingMeta?.twoFlange || 0,
      trimFourSides: pricingMeta?.trimFourSides || 0,
      trimTwoSides: pricingMeta?.trimTwoSides || 0,
      squareDownFillInsert: pricingMeta?.squareDownFillInsert || 0,
      wideDownFillInsert: pricingMeta?.wideDownFillInsert || 0,
      squareHypoFillInsert: pricingMeta?.squareHypoFillInsert || 0,
      wideHypoFillInsert: pricingMeta?.wideHypoFillInsert || 0,
    },
    validationSchema: object({
      widePillowBase: string(),
      squarePillowBase: string(),
      verticalBands: string(),
      insetBands: string(),
      centerBands: string(),
      oneFlange: string(),
      twoFlange: string(),
      trimFourSides: string(),
      trimTwoSides: string(),
      squareDownFillInsert: string(),
      wideDownFillInsert: string(),
      squareHypoFillInsert: string(),
      wideHypoFillInsert: string(),
    }),

    onSubmit: async (values) => {
      setLoading(true)
      const pricingInput = {
        widePillowBase: values.widePillowBase,
        squarePillowBase: values.squarePillowBase,
        verticalBands: values.verticalBands,
        insetBands: values.insetBands,
        centerBands: values.centerBands,
        oneFlange: values.oneFlange,
        twoFlange: values.twoFlange,
        trimFourSides: values.trimFourSides,
        trimTwoSides: values.trimTwoSides,
        wideDownFillInsert: values.wideDownFillInsert,
        wideHypoFillInsert: values.wideHypoFillInsert,
        squareDownFillInsert: values.squareDownFillInsert,
        squareHypoFillInsert: values.squareHypoFillInsert
      }

      updatePricingMutation({
        variables: {
          data: {
            companyId: fabricCompanyId,
            pricingObj: pricingInput
          }
        }
      })
        .then((response) => {
          setLoading(false)
          refetchPricingData()
          // IF USER IS NOT VERIFIED
          if (response?.data?.updateCompanyMetaPricing) {
            toast.success(`Pricing Updated`)
            validation.resetForm()
          }
        }).catch((error) => {
          setLoading(false);
          toast.error(error.message)
        }
        )


    },
  });


  return (
    <>
      <Header />
      <form autoComplete="off" className="tablelist-form" onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
      }}>
        <div className='bg-gray-100 p-5'>
          <div className='p-3 sm:p-5 bg-white'>
            <h2 className='text-lg font-semibold'>Order Pricing</h2>

            {/* HEADER */}
            <div className='mt-5'>
              <div className='grid grid-cols-12 gap-4 bg-gray-100 py-4 ps-3'>
                <div className='col-span-2 sm:col-span-4'>
                  <span className='text-md font-semibold'># No</span>
                </div>
                <div className='col-span-5 sm:col-span-4'>
                  <span className='text-md font-semibold'>Resources</span>
                </div>
                <div className='col-span-5 sm:col-span-4'>
                  <span className='text-md font-semibold'>Labor Price</span>
                </div>
              </div>
            </div>

            {/* ALL COLUMN */}
            <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-pricing-row text-[15.5px] font-[400] text-gray-500'>

              <div className='col-span-2 sm:col-span-4'>
                <span>1</span>
              </div>
              <div className='col-span-5 sm:col-span-4'>
                <span>12 x 20 Pillow Base size</span><br />
                <span className=' mt-1'>(No other option)</span>
              </div>
              <div className='col-span-5 sm:col-span-4 relative'>
                <i className='absolute top-[50%] left-[17px] translate-y-[-50%] translate-x-[-50%]'>$</i>
                <input
                  name="widePillowBase"
                  type="text"
                  value={validation.values.widePillowBase || 0}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  className='rounded-[4px] text-end border-gray-300 text-[15.5px] font-[400] text-gray-500 w-1/2 focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
              </div>
            </div>
            <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-pricing-row text-[15.5px] font-[400] text-gray-500'>

              <div className='col-span-2 sm:col-span-4'>
                <span>2</span>
              </div>
              <div className='col-span-5 sm:col-span-4'>
                <span>20 x 20 Pillow Base size</span><br />
                <span className=' mt-1'>(No other option)</span>
              </div>
              <div className='col-span-5 sm:col-span-4 relative'>
                <i className='absolute top-[50%] left-[17px] translate-y-[-50%] translate-x-[-50%]'>$</i>
                <input
                  name="squarePillowBase"
                  type="text"
                  value={validation.values.squarePillowBase || 0}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  className='rounded-[4px] text-end border-gray-300 text-[15.5px] font-[400] text-gray-500 w-1/2 focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
              </div>
            </div>

            <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-pricing-row text-[15.5px] font-[400] text-gray-500'>

              <div className='col-span-2 sm:col-span-4'>
                <span>3</span>
              </div>
              <div className='col-span-5 sm:col-span-4'>
                <span>Vertical Bands</span><br />
              </div>
              <div className='col-span-5 sm:col-span-4 relative'>
                <i className='absolute top-[50%] left-[17px] translate-y-[-50%] translate-x-[-50%]'>$</i>
                <input
                  name="verticalBands"
                  type="text"
                  value={validation.values.verticalBands || 0}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  className='rounded-[4px] text-end border-gray-300 text-[15.5px] font-[400] text-gray-500 w-1/2 focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
              </div>
            </div>

            <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-pricing-row text-[15.5px] font-[400] text-gray-500'>

              <div className='col-span-2 sm:col-span-4'>
                <span>4</span>
              </div>
              <div className='col-span-5 sm:col-span-4'>
                <span>Inset Bands</span><br />
              </div>
              <div className='col-span-5 sm:col-span-4 relative'>
                <i className='absolute top-[50%] left-[17px] translate-y-[-50%] translate-x-[-50%]'>$</i>
                <input
                  name="insetBands"
                  type="text"
                  value={validation.values.insetBands || 0}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  className='rounded-[4px] text-end border-gray-300 text-[15.5px] font-[400] text-gray-500 w-1/2 focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
              </div>
            </div>

            <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-pricing-row text-[15.5px] font-[400] text-gray-500'>

              <div className='col-span-2 sm:col-span-4'>
                <span>5</span>
              </div>
              <div className='col-span-5 sm:col-span-4'>
                <span>Center Bands</span><br />
              </div>
              <div className='col-span-5 sm:col-span-4 relative'>
                <i className='absolute top-[50%] left-[17px] translate-y-[-50%] translate-x-[-50%]'>$</i>
                <input
                  name="centerBands"
                  type="text"
                  value={validation.values.centerBands || 0}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  className='rounded-[4px] text-end border-gray-300 text-[15.5px] font-[400] text-gray-500 w-1/2 focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
              </div>
            </div>

            <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-pricing-row text-[15.5px] font-[400] text-gray-500'>

              <div className='col-span-2 sm:col-span-4'>
                <span>6</span>
              </div>
              <div className='col-span-5 sm:col-span-4'>
                <span>1" Flange</span><br />
              </div>
              <div className='col-span-5 sm:col-span-4 relative'>
                <i className='absolute top-[50%] left-[17px] translate-y-[-50%] translate-x-[-50%]'>$</i>
                <input
                  name="oneFlange"
                  type="text"
                  value={validation.values.oneFlange || 0}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  className='rounded-[4px] text-end border-gray-300 text-[15.5px] font-[400] text-gray-500 w-1/2 focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
              </div>
            </div>

            <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-pricing-row text-[15.5px] font-[400] text-gray-500'>

              <div className='col-span-2 sm:col-span-4'>
                <span>7</span>
              </div>
              <div className='col-span-5 sm:col-span-4'>
                <span>2" Flange</span><br />
              </div>
              <div className='col-span-5 sm:col-span-4 relative'>
                <i className='absolute top-[50%] left-[17px] translate-y-[-50%] translate-x-[-50%]'>$</i>
                <input
                  name="twoFlange"
                  type="text"
                  value={validation.values.twoFlange || 0}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  className='rounded-[4px] text-end border-gray-300 text-[15.5px] font-[400] text-gray-500 w-1/2 focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
              </div>
            </div>

            <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-pricing-row text-[15.5px] font-[400] text-gray-500'>

              <div className='col-span-2 sm:col-span-4'>
                <span>8</span>
              </div>
              <div className='col-span-5 sm:col-span-4'>
                <span>Trim 4 Sides</span><br />
              </div>
              <div className='col-span-5 sm:col-span-4 relative'>
                <i className='absolute top-[50%] left-[17px] translate-y-[-50%] translate-x-[-50%]'>$</i>
                <input
                  name="trimFourSides"
                  type="text"
                  value={validation.values.trimFourSides || 0}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  className='rounded-[4px] text-end border-gray-300 text-[15.5px] font-[400] text-gray-500 w-1/2 focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
              </div>
            </div>

            <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-pricing-row text-[15.5px] font-[400] text-gray-500'>

              <div className='col-span-2 sm:col-span-4'>
                <span>9</span>
              </div>
              <div className='col-span-5 sm:col-span-4'>
                <span>Trim 2 Sides</span><br />
              </div>
              <div className='col-span-5 sm:col-span-4 relative'>
                <i className='absolute top-[50%] left-[17px] translate-y-[-50%] translate-x-[-50%]'>$</i>
                <input
                  name="trimTwoSides"
                  type="text"
                  value={validation.values.trimTwoSides || 0}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  className='rounded-[4px] text-end border-gray-300 text-[15.5px] font-[400] text-gray-500 w-1/2 focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
              </div>
            </div>


            <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-pricing-row text-[15.5px] font-[400] text-gray-500'>
              <div className='col-span-2 sm:col-span-4'>
                <span>10</span>
              </div>
              <div className='col-span-5 sm:col-span-4'>
                <span>20 x 20 </span><br />
                <span className=' mt-1'>90/10 Down Fill Insert</span>
              </div>
              <div className='col-span-5 sm:col-span-4 relative'>
                <i className='absolute top-[50%] left-[17px] translate-y-[-50%] translate-x-[-50%]'>$</i>
                <input
                  name="squareDownFillInsert"
                  type="text"
                  value={validation.values.squareDownFillInsert || 0}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  className='rounded-[4px] text-end border-gray-300 text-[15.5px] font-[400] text-gray-500 w-1/2 focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
              </div>
            </div>

            <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-pricing-row text-[15.5px] font-[400] text-gray-500'>
              <div className='col-span-2 sm:col-span-4'>
                <span>11</span>
              </div>
              <div className='col-span-5 sm:col-span-4'>
                <span>12 x 20  </span><br />
                <span className=' mt-1'>90/10 Down Fill Insert</span>
              </div>
              <div className='col-span-5 sm:col-span-4 relative'>
                <i className='absolute top-[50%] left-[17px] translate-y-[-50%] translate-x-[-50%]'>$</i>
                <input
                  name="wideDownFillInsert"
                  type="text"
                  value={validation.values.wideDownFillInsert || 0}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  className='rounded-[4px] text-end border-gray-300 text-[15.5px] font-[400] text-gray-500 w-1/2 focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
              </div>
            </div>


            <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-pricing-row text-[15.5px] font-[400] text-gray-500'>
              <div className='col-span-2 sm:col-span-4'>
                <span>12</span>
              </div>
              <div className='col-span-5 sm:col-span-4'>
                <span>20 x 20 </span><br />
                <span className=' mt-1'>Hypoallergenic Fill Insert</span>
              </div>
              <div className='col-span-5 sm:col-span-4 relative'>
                <i className='absolute top-[50%] left-[17px] translate-y-[-50%] translate-x-[-50%]'>$</i>
                <input
                  name="squareHypoFillInsert"
                  type="text"
                  value={validation.values.squareHypoFillInsert || 0}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  className='rounded-[4px] text-end border-gray-300 text-[15.5px] font-[400] text-gray-500 w-1/2 focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
              </div>
            </div>


            <div className='grid grid-cols-12 gap-4 py-3 ps-3 items-center order-pricing-row text-[15.5px] font-[400] text-gray-500'>
              <div className='col-span-2 sm:col-span-4'>
                <span>13</span>
              </div>
              <div className='col-span-5 sm:col-span-4'>
                <span>12 x 20  </span><br />
                <span className=' mt-1'>Hypoallergenic Fill Insert</span>
              </div>
              <div className='col-span-5 sm:col-span-4 relative'>
                <i className='absolute top-[50%] left-[17px] translate-y-[-50%] translate-x-[-50%]'>$</i>
                <input
                  name="wideHypoFillInsert"
                  type="text"
                  value={validation.values.wideHypoFillInsert || 0}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  className='rounded-[4px] text-end border-gray-300 text-[15.5px] font-[400] text-gray-500 w-1/2 focus:border-accent transition duration-300 ease-in-out focus:outline-0 focus:ring-0' />
              </div>
            </div>



          </div>

          <div className='w-full flex justify-end pe-1 mt-4 wrapper'>

            {/* <Button
              className="btn btn-dark mt-3"

            >
              Submit
              {loading && <Spin className="submit-loader" />}
            </Button> */}
            <button
              className="btn btn-outline-primary  mt-3 ms-3"
            >
              Save
            </button>
            <button
              className="btn btn-outline-primary  mt-3 ms-3"
              onClick={() => {
                history(`/orders`)
              }}
            >
              Cancel
            </button>
            {/* <Button
              onClick={() => {
                history(`/orders`)
              }}
              className="btn btn-light mt-3 ms-3"
              style={{ border: '1px solid' }}
            >
              Cancel

            </Button> */}
          </div>
        </div>
      </form >
    </>
  )
}
export default Pricing